import React from 'react';
import { TypeAnimation } from 'react-type-animation';

function Loading3DText({handleStartExperience}) {


  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <TypeAnimation
      sequence={[
        'Sono Claudio Boso',
        1000, 
        'Presidente e fondatore di Care Holding S.p.A.',
        1000,
        'e sono sempre stato un sognatore.',
        1000,
        'Sin da piccolo. Sognavo di realizzare qualcosa di grande e importante…',
        2000,
        // 'e sono sempre stato un sognatore.',
        // 1000,
        // 'Sin da piccolo.',
        // 1000,
        // 'Sognavo di realizzare qualcosa di grande e importante . . .',
        // 1000,
        () => {
            handleStartExperience() 
        }
      ]}
      wrapper="span"
      speed={50}
      style={{ fontSize: '2em', display: 'inline-block' }}
      repeat={false}
  />
    </div>
  );
}

export default Loading3DText;
