import { useEffect, useRef, useState } from "react";
import GlassEffectBlock from "../../components/GlassEffectBlock";
import Navbar from "../../components/Navbar";
import sfondoMaiMollare from "../../assets/images/Book_Guerini_Scontorno.png"
import FooterNew from "../../components/FooterNew";

export default function Libri() {
  const [width, setWidth] = useState(window.innerWidth);



  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const titolo = <>
  <span className="text-black text-left">L’ORO COME BENE RIFUGIO NELLE SCELTE DELLE FAMIGLIE ITALIANE</span>
  </>
  const text = <>
    <div className={`text-black text-sm md:${ width < 1600 && width > 425 ? 'text-base' : 'text-lg'}`}>
      <p className="text-left mb-3 ">
        L’oro ha sempre rappresentato una certezza nei momenti di incertezza, un bene rifugio capace di attraversare epoche e crisi mantenendo intatto il suo valore.
      </p>
      <p className="text-left mb-3 ">
      "Investire con Re Mida", curato dalla Prof.ssa Carolina Guerini della Facoltà di Economia dell’Università Carlo Cattaneo (LIUC) e collaboratrice dell’Università SDA Bocconi School of Business di Milano, offre una guida chiara e approfondita per comprendere l’importanza di questo asset e il suo impatto sulle famiglie italiane.
      </p>
      <p className="text-left mb-3 ">
      Il libro esplora, attraverso dati e analisi, il ruolo dell’oro per le famiglie, le motivazioni che spingono a sceglierlo come investimento e i risultati di un’indagine sulla percezione degli italiani nei confronti dei beni rifugio. Il testo prende come case-history l’azienda leader del settore dell’oro da investimento Careisgold S.p.A., un caso di successo nel mercato della distribuzione del prezioso metallo in Italia, evidenziando strategie e prospettive di crescita.
      </p>
      <p className="text-left mb-3 ">
        Uno strumento prezioso per chi vuole capire perché l’oro continua a essere un pilastro per la sicurezza economica e come comprendere questo mondo in modo consapevole.
      </p>
      <p className="text-left mb-3 ">
      “L’Oro non deve più essere un lusso per pochi, ma un’opportunità per proteggere i risparmi, grazie alla quale tutti possano affrontare con sicurezza le incertezze del domani”. <br/> CLAUDIO BOSO, Presidente di Careisgold S.p.A.
      </p>
    </div>
  </>

    
    const capitoliPrimaParte = [
      "1.1 Il significato storico dell’oro quale mezzo di scambio e deposito di ricchezza",
      "2.2 Il mercato globale dell’oro nella nuova architettura finanziaria",
      "3.3 Il significato simbolico dell’oro",
      "4.4 L’oro come bene rifugio oggi",
    ]
    const titoloPrimaParte= <>1 L’oro come bene rifugio: dalle origini a oggi il ruolo dell’investimento in oro per le famiglie</>

    const contenutoPrimaParte = <>
      {width >= 740 && <h2 className="text-4xl mb-5 mt-5 2xl:mt-0">Indice:</h2>}
      <ul className="text-left mb-14 w-full pl-10 2xl:pl-28 px-5 grid grid-cols-1 md:grid-cols-2 border-b-2 md:border-0 border-black pb-10 md:pb-0">
        {capitoliPrimaParte.map((capitolo, index) => (
            <li key={index} className="mt-5 lg:mt-8">
                <span className="2xl:text-xl">
                    <p className="font-normal 2xl:font-bold">{capitolo}</p>
                </span>
            </li>
        ))}
      </ul>
    </>

    const capitoliSecondaParte = [
      "2.2 Impiego del risparmio e beni rifugio: un approfondimento",
      "2.3 Benessere economico, beni rifugio e atteggiamento verso l’investimento in oro della popolazione italiana: un’indagine campionaria",
      "2.4 Conclusioni"
    ]
    const titoloSecondaParte = <>2 Benessere economico, beni rifugio e atteggiamento verso l’investimento in oro: i risultati di un’indagine sulla popolazione italiana</>
    const contenutoSecondaParte = <>
     <ul className="text-left mb-14 w-full pl-10 2xl:pl-28 px-5 grid grid-cols-1 md:grid-cols-2 border-b-2 md:border-0 border-black pb-10 md:pb-0">
        {capitoliSecondaParte.map((capitolo, index) => (
            <li key={index} className="mt-5 lg:mt-8">
                <span className="2xl:text-xl">
                    <p className="font-normal 2xl:font-bold">{capitolo}</p>
                </span>
            </li>
        ))}
      </ul>
    </>

    const capitoliTerzaParte = [
      "3.1 Le principali tappe dello sviluppo di Careisgold",
      "3.2 Il piano marketing aziendale",
      "3.3 I risultati della ricerca sui clienti Careisgold",
      "4.4 Riflessioni sull’esperienza aziendale",
    ]
    const titoloTerzaParte = <>3 Careisgold S.p.A.: diagnosi della crescita di un operatore del mercato della distribuzione di oro fisico</>
    const contenutoTerzaParte = <>
     <ul className="text-left mb-14 w-full pl-10 2xl:pl-28 px-5 grid grid-cols-1 md:grid-cols-2 ">
        {capitoliTerzaParte.map((capitolo, index) => (
            <li key={index} className="mt-5 lg:mt-8">
                <span className="2xl:text-xl">
                    <p className="font-normal 2xl:font-bold">{capitolo}</p>
                </span>
            </li>
        ))}
      </ul>
    </>


    return <>
      <div className="" data-aos="fade-in" data-aos-duration="2000">
        <Navbar />
          {/* HERO SECTION */}
          <GlassEffectBlock 
            backgroundImage={sfondoMaiMollare} 
            title={titolo} 
            text={text} 
            // image={libroMaiMollare} 
            buttonText={'Acquista'} 
            imgDivClass={` ${width > 2000 ? 'mt-[50rem]' : '2xl:mt-[35rem] mt-[35rem] md:mt-[25rem] xl:mt-[38rem]'}`}
            href='/shop/re-mida'
            />
        </div>


        <div className="py-10 bg-white">
          <div className="uppercase py-7">
            <h3 className="text-4xl xl:text-5xl">Indice</h3>
          </div>
          <div className="flex flex-col md:flex-row md:justify-around md:px-10">
            <div className="border-b-2 md:border-0 border-black pb-10 md:pb-0">
              <div className="px-10 ">
                <h3 className="py-5 font-bold text-xl md:text-xl xl:text-2xl">{titoloPrimaParte}</h3>
                <ul>
                  {capitoliPrimaParte.map((capitolo, index) => (
                    <li key={index} className="mt-3 text-left">
                        <span className={`text-sm md:${ width < 1600 && width > 425 ? 'text-base' : 'text-lg'}`}>
                            <p className="font-normal ">{capitolo}</p>
                        </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="border-b-2 md:border-0 border-black pb-10 md:pb-0">
              <div className="px-10 pt-10 md:pt-0">
                <h3 className="py-5 font-bold text-xl md:text-xl xl:text-2xl">{titoloSecondaParte}</h3>
                <ul>
                  {capitoliSecondaParte.map((capitolo, index) => (
                    <li key={index} className="mt-3 text-left">
                        <span className={`text-sm md:${ width < 1600 && width > 425 ? 'text-base' : 'text-lg'}`}>
                            <p className="font-normal ">{capitolo}</p>
                        </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="pb-10 md:pb-0">
              <div className="px-10 pt-10 md:pt-0">
                <h3 className="py-5 font-bold text-xl md:text-xl xl:text-2xl">{titoloTerzaParte}</h3>
                <ul>
                  {capitoliTerzaParte.map((capitolo, index) => (
                    <li key={index} className="mt-3 text-left">
                        <span className={`text-sm md:${ width < 1600 && width > 425 ? 'text-base' : 'text-lg'}`}>
                            <p className="font-normal ">{capitolo}</p>
                        </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      <FooterNew/>
    </>
}