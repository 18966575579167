import { useEffect, useRef } from 'react';

const TolstoyCarouselWidget = ({ onLoad }) => {
  const tolstoyContainerRef = useRef(null);

  useEffect(() => {
    if (!tolstoyContainerRef.current) return;

    const existingCarousel = document.getElementById('oujwtdx5bad8q');

    // If no existing carousel, dynamically add the widget
    if (!existingCarousel) {
      const script = document.createElement('script');
      script.src = 'https://widget.gotolstoy.com/script.js';
      script.async = true;

      const carousel = document.createElement('tolstoy-carousel');
      carousel.setAttribute('id', 'oujwtdx5bad8q');
      carousel.setAttribute('class', 'tolstoy-carousel');
      carousel.setAttribute('data-product-id', 'your-product-id');
      
      script.onload = () => {
        const carouselContainer = tolstoyContainerRef.current;
        if (carouselContainer) {
          carouselContainer.appendChild(carousel);
        }
        if (onLoad) onLoad(); // Call onLoad function from parent
      };

      document.body.appendChild(script);

      // Cleanup script and carousel on component unmount
      return () => {
        document.body.removeChild(script);
        if (carousel) {
          carousel.remove();
        }
      };
    }
  }, [onLoad]);

  return <div ref={tolstoyContainerRef} className='md:mt-20'></div>;
};

export default TolstoyCarouselWidget;
