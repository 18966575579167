import React from 'react';
import { ScrollParallax } from "react-just-parallax";
import { Link } from 'react-router-dom';
import DefaultButton from './DefaultButton';

function MinimalSection({ orientation, background, title, description, href, imageUrl, titleClass, subtitle, descriptionClass, buttonText, darkButton, strength, lerp, imgOpacity, firstButtonText, buttonClass1, buttonClass2, secondButton, secondButtonText, href2 }) {


  return (
    <>
    {orientation === 'left' ? (
        <>
            <section>
                <div className="flex">
                    {/* Image Section */}
                    <div className="w-3/5 h-screen sticky top-0 overflow-hidden hidden md:block">
                        <img src={imageUrl} alt={title} className="w-full h-full object-cover" />
                    </div>

                    {/* Text Section */}
                    <div className={`w-full h-full inset-0 ${background}`}>
                        <div className="relative text-white h-full pt-64 text-center flex flex-col items-center">
                            <div className="text-left mb-8 w-full max-w-2xl px-5">
                                <p className="text-lg uppercase mb-4">{subtitle}</p>
                                <h1 className={`text-4xl font-bold mb-4 ${titleClass}`}>{title}</h1>
                                <div className={`text-base mb-8 ${descriptionClass}`}>{description}</div>
                                <DefaultButton text={firstButtonText || 'Scopri di più'} link={href} dark={darkButton} buttonClass={buttonClass1} />
                                {secondButton && <DefaultButton text={secondButtonText || 'Scopri di più'} link={href2} dark={darkButton} buttonClass={buttonClass2} />}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    ) : (
        <>
            <section>
                <div className="flex">

                    {/* Text Section */}
                    <div className={`w-full h-full inset-0 ${background}`}>
                        <div className="relative text-white h-full pt-64 text-center flex flex-col items-center">
                            <div className="text-left mb-8 w-full max-w-2xl px-5">
                                <p className="text-lg uppercase mb-4">{subtitle}</p>
                                <h1 className={`text-4xl font-bold mb-4 ${titleClass}`}>{title}</h1>
                                <div className={`text-base mb-8 ${descriptionClass}`}>{description}</div>
                                <DefaultButton text={firstButtonText || 'Scopri di più'} link={href} dark={darkButton} buttonClass={buttonClass1} />
                                {secondButton && <DefaultButton text={secondButtonText || 'Scopri di più'} link={href2} dark={darkButton} buttonClass={buttonClass2} />}
                            </div>
                        </div>
                    </div>

                    {/* Image Section */}
                    <div className="w-3/5 h-screen sticky top-0 overflow-hidden hidden md:block">
                        <img src={imageUrl} alt={title} className="w-full h-full object-cover" />
                    </div>

                </div>
            </section>        
        </>
    )}
    </>
  );
}

export default MinimalSection;


