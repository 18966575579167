import Navbar from "../components/Navbar";
import FooterNew from "../components/FooterNew";
import libro from "../assets/images/Mockup_MaiMollare.jpg"
import amazonLogo from "../assets/images/amazonKindle.png"

export default function ShopMaiMollare() {
    return<>
      <div className="" data-aos="fade-in" data-aos-duration="2000">
        <Navbar />
            <section className="flex flex-col md:flex-row md:h-screen mb-20 md:mb-0">
                <div className="w-[100%] md:w-[50%] p-20 md:p-0 bg-white flex justify-center">
                    <img src={libro} alt="" className="object-cover"/>
                </div>
                <div className="w-[100%] md:w-[50%] mt-7 md:mt-0 bg-black text-white flex justify-center">
                    <div className="self-center text-md 2xl:text-lg w-[70%] text-slate-200 mt-16 2xl:mt-0">
                        <ul>
                            <li className="flex justify-between mb-3">
                                <span className="font-bold">Titolo:</span><span> Mai Mollare</span> 
                            </li>
                            <li className="flex justify-between mb-3">
                                <span className="font-bold">Autore:</span> Claudio Boso
                            </li>
                            <li className="flex justify-between mb-3">
                                <span className="font-bold">Pagine:</span> 375
                            </li>
                            <li className="flex justify-between mb-3">
                                <span className="font-bold">ISBN:</span> 978-88-944949-0-7
                            </li>
                        </ul>
                        <hr className="pb-5 2xl:py-4 w-[120%] -mx-[10%]"/>
                        <ul>
                            <li className="flex justify-between mb-3">
                                <span className="font-bold">Prezzo listino:</span><span>25,00 €</span>
                            </li>
                            <li className="flex justify-between mb-3">
                                <span className="font-bold">Prezzo di spedizione:</span><span className="line-through">5,00 €</span> 
                            </li>
                        </ul>
                        <hr  className="pb-5 2xl:py-4 w-[120%] -mx-[10%]"/>
                        <ul>
                            <li className="flex justify-between mb-3">
                                <span className="font-bold">Totale:</span> <span className="line-through">30,00 €</span> 
                            </li>
                            <li className="flex justify-between mb-3">
                                <span className="font-bold"></span> <span >25,00 €</span> 
                            </li>
                        </ul>
                        <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top" className="mt-5 2xl:mt-12">
                                <input type="hidden" name="cmd" value="_s-xclick" />
                                <input type="hidden" name="hosted_button_id" value="LHSDXKXDHTY9E" />
                                <input type="image" src="https://www.paypalobjects.com/it_IT/IT/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" alt="PayPal è il metodo rapido e sicuro per pagare e farsi pagare online." className="w-52 "/>
                                <img alt="" border="0" src="https://www.paypalobjects.com/it_IT/i/scr/pixel.gif" width="1" height="1" />
                        </form>
                            <a href="https://www.amazon.it/dp/B08BPBTHDD" target="_blank"  rel="noreferrer" className="text-black ">
                                <div className="bg-slate-100 hover:bg-slate-300 rounded mt-9 flex justify-center">
                                    <img src={amazonLogo} alt="amazon kindle logo" className="p-2"/>
                                </div>
                            </a>
                    </div>

                </div>
            </section>
        <FooterNew/>
        </div>
    </>
}