import { useEffect, useRef, useState } from "react";
import GlassEffectBlock from "../components/GlassEffectBlock";
import Navbar from "../components/Navbar";
import sfondoMaiMollare from "../assets/images/Gravity-HardCover-Book-Scene-Free-psd-Mockup.png"
import sfondoLibiri from "../assets/images/Mockup_MaiMollare-ReMida.jpg"
import FooterNew from "../components/FooterNew";
import primaParte from "../assets/images/prima-parte.jpg"
import secondaParte from "../assets/images/seconda-parte.jpg"
import terzaParte from "../assets/images/terza-parte.webp"
import HalfHalf from "../components/HalfHalf";
import DefaultButton from "../components/DefaultButton";

export default function Libri() {
  const [width, setWidth] = useState(window.innerWidth);



  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const titolo = <>
  <span className="text-white text-left">Lorem, ipsum dolor sit sicing elit.<br/> Dolor est maxime earum ab nisi amet nemo  quibusdam pariatur incidunt cumque ut.</span>
  </>
  const text = <>
    <div className={`text-slate-50 text-sm md:${ width < 1600 && width > 425 ? 'text-base' : 'text-lg'}`}>
      <p className="text-left mb-3 ">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia voluptatem visi earum nam omnis temporibus laudantium qui non modi inventore, iignissimos ipsam reiciendis, sint eius molestias iure sapiente sit quia. rorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat repudiandae aibero dolor, ut iure, quia rerum officia expedita, distinctio reprehenderit jorporis. Explicabo vero obcaecati temporibus ullam? Nesciunt sit magnam praesentium. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dicta minus maxime sequi soluta itaque aliquid quidem tempore mollitia veniam, culpa quis a vel laudantium explicabo dolore quaerat ad fuga ea.
      </p>
    </div>
  </>



    return <>
      <div className="" data-aos="fade-in" data-aos-duration="2000">
        <Navbar />
            <div className="md:flex">
              <div className="w-full">
                  <div 
                      className='h-screen overflow-hidden flex justify-center items-center' 
                      style={{ 
                          backgroundImage: `url(${sfondoLibiri})`, 
                          backgroundSize: 'cover', 
                          backgroundPosition: 'top', 
                          backgroundRepeat: 'no-repeat' 
                      }}
                  >
                      <div className={` ${width > 2000 ? 'mt-[50rem]' : '2xl:mt-[45rem] mt-[35rem] md:mt-[25rem] xl:mt-[38rem]'}`}>
                          <div className={`p-3 mb-5 flex justify-between gap-20`}>
                              <DefaultButton text="Mai Mollare" addClass='font-bold w-full m-2' dark link='/libri/mai-mollare'/>
                              <DefaultButton text='Investire con Re Mida' addClass='font-bold w-full m-2 text-sm md:text-base' dark link='/libri/re-mida'/>
                          </div>
                      </div>
                  </div>
              </div>
              {/* <div className="xl:w-2/5 md:w-1/2 bg-black h-screen overflow-hidden  content-center">
                  <div className="lg:p-6 xl:p-8 p-[1.2rem] md:mt-10 2xl:mt-0">
                      <h1 className="text-sm md:text-lg xl:text-xl 2xl:text-2xl mb-4 2xl:mb-10 font-bold text-left uppercase mt-14 sm:mt-0 ">{titolo}</h1>
                      <section className="text-xs md:text-sm lg:text-sm 2xl:text-lg tracking-wider">{text}</section>
                  </div>
              </div> */}
          </div>
        </div>
      <FooterNew/>
    </>
}