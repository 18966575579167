import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
// import { hydrate } from "react-dom";
// import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    gtmId: 'GTM-5NQKNKQ'
}

// const tagManagerArgs = {
//     gtmId: 'GTM-5VBL7N7'
// }

// const GtmConfig = () => {
//   useEffect(() => {
//     TagManager.initialize(tagManagerArgs);
//   }, []);
// }


// const rootElement = document.getElementById("root");
// const root = createRoot(rootElement); 


// if (rootElement.hasChildNodes()) {
//   hydrate(  
//     <React.StrictMode>
//       <App />
//       <GtmConfig/>
//     </React.StrictMode>
//   , rootElement);
// } else {
//   root.render(  
//     <React.StrictMode>
//       <App />
//       <GtmConfig/>
//     </React.StrictMode>
// );
// }

// reportWebVitals();

TagManager.initialize(tagManagerArgs)


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);
