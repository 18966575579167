import { Float, Scroll, useScroll, useTexture, useGLTF, Clone } from "@react-three/drei";
import { useFrame, useThree } from "react-three-fiber";
import { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';

import woodTexture from '../assets/textures/Texturelabs_Wood_264M.jpg'

import infanzia from '../assets/polaroid/1.png'
import studi from '../assets/polaroid/2.png'
import mestre from '../assets/polaroid/3.png'
import bivio from '../assets/polaroid/4.png'
import careHolding from '../assets/polaroid/5.png'
import madre from '../assets/polaroid/6.png'
import oroPuroFisico from '../assets/polaroid/7.png'
import careIsGold from '../assets/polaroid/8.png'
import logo from '../assets/Risorsa-1.png'

import chessModel from '../assets/models/chess_board_1.glb'
import notebookModel from '../assets/models/notebook-freepoly.glb'
import smartphoneModel from '../assets/models/realistic_smartphone_3d_model.glb'
import notepadModel from '../assets/models/single_spiral_notepad.glb'
import goldbarModel from '../assets/goldbar_20g.glb'


gsap.registerPlugin(useGSAP);

export default function Experience3({startExperience}) {
    const [isVisible, setIsVisible] = useState(false)
    const [infanziaSpeed, setInfanziaSpeed] = useState(0)
    const [studiSpeed, setStudiSpeed] = useState(0)
    const [mestreSpeed, setMestreSpeed] = useState(0)
    const [bivioSpeed, setBivioSpeed] = useState(0)
    const [careHoldingSpeed, setCareHoldingSpeed] = useState(0)
    const [madreSpeed, setMadreSpeed] = useState(0)
    const [oroPuroFisicoSpeed, setOroPuroFisicoSpeed] = useState(0)
    const [careIsGoldSpeed, setCareIsGoldSpeed] = useState(0)

    const chessScene = useGLTF(chessModel)
    const notebookScene = useGLTF(notebookModel)
    const smartphoneScene = useGLTF(smartphoneModel)
    const notepadScene = useGLTF(notepadModel)
    const goldbarScene = useGLTF(goldbarModel)
    
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

    const { camera } = useThree(); // Access the default camera
    
    const cameraArrivalPosition ={x: 0, y: 0, z: 0}
    const cameraArrivalRotation = {x: - Math.PI / 2, y: 0, z:0}


    camera.position.x = cameraArrivalPosition.x
    camera.position.y = cameraArrivalPosition.y
    camera.position.z = cameraArrivalPosition.z

    camera.rotation.x = cameraArrivalRotation.x
    camera.rotation.y = cameraArrivalRotation.y
    camera.rotation.z = cameraArrivalRotation.z

    // TEXTURES
    const texture = useTexture({
        map: woodTexture,
    })

    const infanziaTexture = useTexture(infanzia)
    const studiTexture = useTexture(studi)
    const mestreTexture = useTexture(mestre)
    const bivioTexture = useTexture(bivio)
    const careHoldingTexture = useTexture(careHolding)
    const madreTexture = useTexture(madre)
    const oroPuroFisicoTexture = useTexture(oroPuroFisico)
    const careIsGoldTexture = useTexture(careIsGold)
    const logoTexture = useTexture(logo)

    // REFS
    const infanziaPhotoRef = useRef()
    const studiPhotoRef = useRef()
    const mestrePhotoRef = useRef()
    const bivioPhotoRef = useRef()
    const careHoldingPhotoRef = useRef()
    const madrePhotoRef = useRef()
    const oroPuroFisicoPhotoRef = useRef()
    const careIsGoldPhotoRef = useRef()
    const logoTextureRef = useRef()
    const goldTextureRef = useRef()

    // RESPONSIVE
    const responsiveSizes = {
        photosGeometrySizeL: {
            width: 2.2,
            height: 2.2
        },
        photosGeometrySizeS: {
            width: 1.5,
            height: 1.5
        },
        tableSizeL: {
            width: 24,
            height: 11,
            depth: 1
        },
        tableSizeS: {
            width: 13,
            height: 7,
            depth: 1
        },
   }

    const photosInitialPosition = {
        infanzia: { x: -2, y: 0, z: -0.1 },    
        studi: { x: 3.5, y: 1.8, z: -0.4 },     
        mestre: { x: 3.5, y: -1.5, z: -0.5 },   
        bivio: { x: -1.8, y: 1.5, z: -0.6 },  
        careHolding: { x: -0.5, y: -1.5, z: -0.7 },
        madre: { x: 0.7, y: 0, z: -0.8 },  
        oroPuroFisico: { x: 3, y: -0.5, z: -0.9 }, 
        careIsGold: { x: 0.5, y: 2, z: -1},  
    };
   
    // Scroll control
    const scroll = useScroll();
    const previousScrollY = useRef(0);
    
    

    // Infanzia photo animation
    useEffect(() => {
        // setTimeout(() => {
        if(!startExperience){
            gsap.to(infanziaPhotoRef.current.position, {
                // x: -0.2, <-- small screen 
                x: width < 1240 ? -0.2 : -1,
                y:  0.5,
                z: 3,
                duration: 4, // Animation duration
                ease: "power3.out", // Easing function
            })
            gsap.to(infanziaPhotoRef.current.rotation, {
                z: 0,
                x: 0,
                duration: 2,
                ease: "power2.in",
            });
            setInfanziaSpeed(1);
            setIsVisible(true)
        }
        // }, 1000)
    }, [startExperience, width]);
    


    // GSAP Animations
    useFrame(() => {
    const scrollY = scroll.offset; // Get the scroll progress (0 - 1)
  
    // Determine scroll direction
    const isScrollingDown = scrollY > previousScrollY.current;
    const isScrollingUp = scrollY < previousScrollY.current;

    // Calculate the scroll speed (the difference in scroll position between frames)
    const scrollSpeed = Math.abs(scrollY - previousScrollY.current) * 100; // You can scale this to adjust the effect

    const arrivalPosition = {
        x: width < 1240 ? -0.2 : -1,
        y:  0.5,
        z: 3,
        duration:  Math.max(0.5, 1 - scrollSpeed) * 2, // Animation duration
        ease: "power3.out", // Easing function
        overwrite: true
    }

    const arrivalRotation = {
        z: 0,
        x: 0,
        duration:  Math.max(0.5, 1 - scrollSpeed),
        ease: "power2.in",
        overwrite: true

    }

    // Trigger animations at specific scroll ranges
    
    // ----------------------------------------------------------------------------------------------------
    //INFANZIA
    if (isScrollingDown && (scrollY > 0.02 && scrollY < 0.03)) {
        gsap.to(infanziaPhotoRef.current.position, {
            x: -7,
            duration:  Math.max(0.5, 1 - scrollSpeed),
            ease: "power2.in",
            overwrite: true
        });
        gsap.to(infanziaPhotoRef.current.rotation, {
            z: -0.2,
            duration:  Math.max(0.5, 1 - scrollSpeed),
            ease: "power2.in",
            overwrite: true
        });
    }

    if (isScrollingUp && (scrollY > 0.02 && scrollY < 0.03)) {
        gsap.to(infanziaPhotoRef.current.position, {...arrivalPosition, overwrite: true});
        gsap.to(infanziaPhotoRef.current.rotation, {...arrivalRotation, overwrite: true});
    }
    // INFANZIA
    // ----------------------------------------------------------------------------------------------------


    // ----------------------------------------------------------------------------------------------------
    //STUDI

    if (isScrollingDown && (scrollY > 0.06 && scrollY < 0.07)) {

        gsap.to(studiPhotoRef.current.position, {
            ...arrivalPosition, 
            overwrite:true
        });
        gsap.to(studiPhotoRef.current.rotation, {...arrivalRotation, overwrite:true});
        setStudiSpeed(1);
    }

    if (isScrollingDown && (scrollY > 0.16 && scrollY < 0.17)) {
        gsap.to(studiPhotoRef.current.position, {
            x: -7,
            duration:  Math.max(0.5, 1 - scrollSpeed),
            ease: "power2.in",
            overwrite: true
        });
        gsap.to(studiPhotoRef.current.rotation, {
            z: 0.2,
            duration:  Math.max(0.5, 1 - scrollSpeed),
            ease: "power2.in",
            overwrite: true
        });
    }

    // SCROLING UP
    if (isScrollingUp && (scrollY > 0.0623 && scrollY < 0.08)) {
        gsap.to(studiPhotoRef.current.position, {
            x: photosInitialPosition.studi.x,
            y: photosInitialPosition.studi.y,
            z: photosInitialPosition.studi.z,
            duration:  Math.max(0.5, 1 - scrollSpeed),
            ease: "power2.in",
            overwrite: true
        });
        gsap.to(studiPhotoRef.current.rotation, {
            z: 0.2,
            duration:  Math.max(0.5, 1 - scrollSpeed),
            ease: "power2.in",
            overwrite: true
        });
        setStudiSpeed(0);
    }

    if (isScrollingUp && (scrollY > 0.20 && scrollY < 0.21)) {
        gsap.to(studiPhotoRef.current.position, {...arrivalPosition, overwrite: true});
        gsap.to(studiPhotoRef.current.rotation, {...arrivalRotation, overwrite: true});
    }
    // STUDI
    // ----------------------------------------------------------------------------------------------------



    // ----------------------------------------------------------------------------------------------------
    // MESTRE
    // SCROLING DOWN
    if (isScrollingDown && (scrollY > 0.20 && scrollY < 0.21)) {
        gsap.to(mestrePhotoRef.current.position, {...arrivalPosition, overwrite: true});
        gsap.to(mestrePhotoRef.current.rotation, {...arrivalRotation, overwrite: true});
        setMestreSpeed(1);
    }

    if (isScrollingDown && (scrollY > 0.32 && scrollY < 0.33)) {
        gsap.to(mestrePhotoRef.current.position, {
            x: -7,
            duration:  Math.max(0.5, 1 - scrollSpeed),
            ease: "power2.in",
            overwrite: true
        });
        gsap.to(mestrePhotoRef.current.rotation, {
            z: 0.8,
            duration: 1,
            ease: "power2.in",
            overwrite: true
        });
    }

    // SCROLLING UP
    if (isScrollingUp && (scrollY > 0.35 && scrollY < 0.36)) {
        gsap.to(mestrePhotoRef.current.position, {...arrivalPosition, overwrite: true});
        gsap.to(mestrePhotoRef.current.rotation, {...arrivalRotation, overwrite: true});
    }

    if (isScrollingUp && (scrollY > 0.20 && scrollY < 0.22)) {
        gsap.to(mestrePhotoRef.current.position, {
            x: photosInitialPosition.mestre.x,
            y: photosInitialPosition.mestre.y,
            z: photosInitialPosition.mestre.z,
            duration:  Math.max(0.5, 1 - scrollSpeed),
            ease: "power2.in",
            overwrite: true
        });
        gsap.to(mestrePhotoRef.current.rotation, {
            z: 0.2,
            duration:  Math.max(0.5, 1 - scrollSpeed),
            ease: "power2.in",
            overwrite: true
        });
        setMestreSpeed(0);

    }
    // MESTRE
    // ----------------------------------------------------------------------------------------------------


    // ----------------------------------------------------------------------------------------------------
    // BIVIO
    
    // SCROLLING DOWN
    if (isScrollingDown && (scrollY > 0.35 && scrollY < 0.36)) {
        gsap.to(bivioPhotoRef.current.position, {...arrivalPosition, overwrite: true});
        gsap.to(bivioPhotoRef.current.rotation, {...arrivalRotation, overwrite: true});
        setTimeout(() => {
            setBivioSpeed(1);
        },1000)
    }

    if (isScrollingDown && (scrollY > 0.46 && scrollY < 0.47)) {
        gsap.to(bivioPhotoRef.current.position, {
            x: -7,
            duration:  Math.max(0.5, 1 - scrollSpeed),
            ease: "power2.in",
            overwrite: true
        });
        gsap.to(bivioPhotoRef.current.rotation, {
            z: 0.5,
            duration: 1,
            ease: "power2.in",
            overwrite: true
        });
    }

    // SCROLLING UP
    if (isScrollingUp && (scrollY >  0.46 && scrollY < 0.47)) {
        gsap.to(bivioPhotoRef.current.position, {...arrivalPosition, overwrite: true});
        gsap.to(bivioPhotoRef.current.rotation, {...arrivalRotation, overwrite: true});
    }

    if (isScrollingUp && (scrollY > 0.35 && scrollY < 0.36)) {
        gsap.to(bivioPhotoRef.current.position, {
            x: photosInitialPosition.bivio.x,
            y: photosInitialPosition.bivio.y,
            z: photosInitialPosition.bivio.z,
            duration:  Math.max(0.5, 1 - scrollSpeed),
            ease: "power2.in",
            overwrite: true
        });
        gsap.to(bivioPhotoRef.current.rotation, {
            z: 0.2,
            duration:  Math.max(0.5, 1 - scrollSpeed),
            ease: "power2.in",
            overwrite: true
        });
        setBivioSpeed(0);
    }

    // BIVIO
    // ----------------------------------------------------------------------------------------------------


    // ----------------------------------------------------------------------------------------------------
    // CAREHOLDING

    // SCROLLING DOWN
    if (isScrollingDown && (scrollY > 0.48 && scrollY < 0.49)) {
        gsap.to(careHoldingPhotoRef.current.position, {...arrivalPosition, overwrite: true});
        gsap.to(careHoldingPhotoRef.current.rotation, {...arrivalRotation, overwrite: true});
        setTimeout(() => {
            setCareHoldingSpeed(1);
        },1000)
    }

    if (isScrollingDown && (scrollY > 0.60 && scrollY < 0.61)) {
        gsap.to(careHoldingPhotoRef.current.position, {
            x: -7,
            duration:  Math.max(0.5, 1 - scrollSpeed),
            ease: "power2.in",
            overwrite: true
        });
        gsap.to(careHoldingPhotoRef.current.rotation, {
            z: 0.5,
            duration: 1,
            ease: "power2.in",
            overwrite: true
        });
    }

    // SCROLLING UP
    if (isScrollingUp && (scrollY > 0.62 && scrollY < 0.63)) {
        gsap.to(careHoldingPhotoRef.current.position, {...arrivalPosition, overwrite: true});
        gsap.to(careHoldingPhotoRef.current.rotation, {...arrivalRotation, overwrite: true});
    }

    if (isScrollingUp && (scrollY >  0.48 && scrollY < 0.49)) {
        gsap.to(careHoldingPhotoRef.current.position, {
            x: photosInitialPosition.careHolding.x,
            y: photosInitialPosition.careHolding.y,
            z: photosInitialPosition.careHolding.z,
            duration:  Math.max(0.5, 1 - scrollSpeed),
            ease: "power2.in",
            overwrite: true
        });
        gsap.to(careHoldingPhotoRef.current.rotation, {
            z: 0.5,
            duration: 1,
            ease: "power2.in",
            overwrite: true
        });
        setCareHoldingSpeed(0);
    }

    // CAREHOLDING
    // ----------------------------------------------------------------------------------------------------


    // ----------------------------------------------------------------------------------------------------
    // MADRE

    //SCROLING DOWN
    if (isScrollingDown && (scrollY > 0.63 && scrollY < 0.64)) {
        gsap.to(madrePhotoRef.current.position, {...arrivalPosition, overwrite: true});
        gsap.to(madrePhotoRef.current.rotation, {...arrivalRotation, overwrite: true});
        
        setTimeout(() => {
            setMadreSpeed(1);
        },1000)
    }

    if (isScrollingDown && (scrollY > 0.76 && scrollY < 0.77)) {
        gsap.to(madrePhotoRef.current.position, {
            x: -7,
            duration:  Math.max(0.5, 1 - scrollSpeed),
            ease: "power2.in",
            overwrite: true
        });
        gsap.to(madrePhotoRef.current.rotation, {
            z: 0.2,
            duration: 1,
            ease: "power2.in",
            overwrite: true
        });
    }

    //SCROLING UP
    if (isScrollingUp && (scrollY > 0.76 && scrollY < 0.78)) {
        gsap.to(madrePhotoRef.current.position, {...arrivalPosition, overwrite: true});
        gsap.to(madrePhotoRef.current.rotation, {...arrivalRotation, overwrite: true});
    }

    if (isScrollingUp && (scrollY > 0.63 && scrollY < 0.64)) {
        gsap.to(madrePhotoRef.current.position, {
            x: photosInitialPosition.madre.x,
            y: photosInitialPosition.madre.y,
            z: photosInitialPosition.madre.z,
            duration:  Math.max(0.5, 1 - scrollSpeed),
            ease: "power2.in",
            overwrite: true
        });
        gsap.to(madrePhotoRef.current.rotation, {
            z: 0.2,
            duration: 1,
            ease: "power2.in",
            overwrite: true
        });
        setMadreSpeed(0);
    }

    // MADRE
    // ----------------------------------------------------------------------------------------------------


    // ----------------------------------------------------------------------------------------------------
    // ORO PURO FISICO

    // SCROLLING DOWN
    if (isScrollingDown && (scrollY > 0.77 && scrollY < 0.78)) {
        gsap.to(oroPuroFisicoPhotoRef.current.position, {...arrivalPosition, overwrite: true});
        gsap.to(oroPuroFisicoPhotoRef.current.rotation, {...arrivalRotation, overwrite: true});
        setTimeout(() => {
            setOroPuroFisicoSpeed(1);
        },1000)
        
    }

    if (isScrollingDown && (scrollY > 0.93 && scrollY < 0.94)) {
        gsap.to(oroPuroFisicoPhotoRef.current.position, {
            x: -7,
            duration:  Math.max(0.5, 1 - scrollSpeed),
            ease: "power2.in",
            overwrite: true
        });
        gsap.to(oroPuroFisicoPhotoRef.current.rotation, {
            z: -0.4,
            duration: 1,
            ease: "power2.in",
            overwrite: true
        });
    }

    // SCROLLING UP
    if (isScrollingUp && (scrollY > 0.93 && scrollY < 0.94)) {
        gsap.to(oroPuroFisicoPhotoRef.current.position, {...arrivalPosition, overwrite: true});
        gsap.to(oroPuroFisicoPhotoRef.current.rotation, {...arrivalRotation, overwrite: true});
    }

    if (isScrollingUp && (scrollY > 0.77 && scrollY < 0.78)) {
        gsap.to(oroPuroFisicoPhotoRef.current.position, {
            x: photosInitialPosition.oroPuroFisico.x,
            y: photosInitialPosition.oroPuroFisico.y,
            z: photosInitialPosition.oroPuroFisico.z,
            duration:  Math.max(0.5, 1 - scrollSpeed),
            ease: "power2.in",
            overwrite: true
        });
        gsap.to(oroPuroFisicoPhotoRef.current.rotation, {
            z: -0.4,
            duration: 1,
            ease: "power2.in",
            overwrite: true
        });
        setOroPuroFisicoSpeed(0);
    }

    // ORO PURO FISICO
    // ----------------------------------------------------------------------------------------------------


    // ----------------------------------------------------------------------------------------------------
    // CAREISGOLD

    // SCROLLING DOWN
    if (isScrollingDown && (scrollY > 0.93 && scrollY < 0.94)) {
        gsap.to(careIsGoldPhotoRef.current.position, {...arrivalPosition, overwrite: true});
        gsap.to(careIsGoldPhotoRef.current.rotation, {...arrivalRotation, overwrite: true});
        
        setTimeout(() => {
            setCareIsGoldSpeed(1);
        },1000)
    }

    // SCROLLING UP
    if (isScrollingUp && (scrollY > 0.93 && scrollY < 0.94)) {
        gsap.to(careIsGoldPhotoRef.current.position, {
            x: photosInitialPosition.careIsGold.x,
            y: photosInitialPosition.careIsGold.y,
            z: photosInitialPosition.careIsGold.z,
            duration:  Math.max(0.5, 1 - scrollSpeed),
            ease: "power2.in",
            overwrite: true
        });
        gsap.to(careIsGoldPhotoRef.current.rotation, {
            z: -0.2,
            duration: 1,
            ease: "power2.in",
            overwrite: true
        });
        setCareIsGoldSpeed(0);
    }


    // CAREISGOLD
    // ----------------------------------------------------------------------------------------------------

        // Update previous scroll position
        previousScrollY.current = scrollY;
    })

    // OBJECTS POSITION

    //TABLE
    const positionTable = {x: 0, y: -6.98, z: 0}
    const rotationTable = {x: - Math.PI/2, y:0, z:0}

    //PHOTOS
    const photosPosition = {x: -1.18, y:-5.70, z:0.61}
    const photosRotation = {x: Math.PI / 2, y: Math.PI, z:Math.PI}

    // CHESSBOARD
    const chessPosition = {x:8.88, y:-6.99, z:-3.75}
    const chessRotation = {x: 0, y:2.03, z:0}

    // NOTEBOOK
    const notebookPosition = {x:24.53, y:-6.97, z: 7.71}
    const notebookRotation = {x: 0, y:-0.60, z: 0}

    // GROUP NOTEPAD LOGO
    const logoBookPosition = {x: -0.82, y: 0.04, z: 0}
    const logoBookRotation = {x: 0, y:0, z: 0}

    // NOTEPAD
    const notepadPositon = {x: -8.93, y: -6.66, z: -1.31}
    const notepadRotation = {x: 0, y:-4.59, z: 0}

    // LOGO
    const logoPosition = {x: -7.48, y: -6.63, z: -1.68}
    const logoRotation = {x: -Math.PI / 2, y:0, z: 0.12}

    // SMARTPHONE
    const smartphonePosition = {x: 6.94, y:-7.05, z: 3.13}
    const smartphoneRotation = {x: 0, y:-0.92, z: 1.58}

    // GROUP GOLDBARS
    const goldbarsPosition = {x:-1.73, y:0, z:1.53}
    const goldbarsRotation = {x: 0, y:0, z: 0}

    // BOTTOM GOLDBAR
    const goldbarPosition = {x: -7.44, y: -7.01, z: 2.32}
    const goldbarRotation = {x: 0, y:-0.14, z: 0}

    // TOP GOLDBAR
    const secondGoldbarPosition = {x: -6.67, y: -6.95, z: 2.44}
    const secondGoldbarRotation = {x:0.02, y: 1.02, z: -0.02}


    //LIGHS POSITION
    const lightPosition = {x:-8.22, y:-0.14, z:-4.05}
    const secondLightPosition = {x:32.60, y:6.41, z: 1.18}
    const goldLightPosition = {x:-15.61, y:-1.40, z:6.87}
    

  
 
    const directionalLight = useRef()
    const secondDirectionalLight = useRef()
    const targetRef = useRef();
    const secondTargetRef = useRef();
    const goldLight = useRef()
    const goldLightTarget = useRef()

    useEffect(() => {
        if (directionalLight.current && targetRef.current) {
            directionalLight.current.target = targetRef.current;
        }
        if (secondDirectionalLight.current && secondTargetRef.current) {
            secondDirectionalLight.current.target = secondTargetRef.current;
        }
        if (goldLight.current && goldLightTarget.current) {
            goldLight.current.target = goldLightTarget.current;
        }

      
      }, []);

      useEffect(() => {
        if (chessScene.scene) {
          chessScene.scene.traverse((child) => {
            if (child.isMesh) {
              child.castShadow = true;
              child.receiveShadow = true;
            }
          });
        }

        if (notebookScene.scene) {
            notebookScene.scene.traverse((child) => {
                if (child.isMesh) {
                  child.castShadow = true;
                }
              });
        }

        if(goldbarScene.scene) {
            goldbarScene.scene.traverse((child) => {
                if (child) {
                    child.castShadow = true;
                    child.receiveShadow = true;
                  }
            } )
        }
      }, [chessScene.scene]);

    //   useHelper(goldLight, THREE.DirectionalLightHelper, 1)

    return<>

    {/* <OrbitControls /> */}
    <ambientLight intensity={1.50}/>
    <directionalLight 
        intensity={1}
        castShadow
        ref={directionalLight} 
        targetRef={targetRef.current}
        position={[lightPosition.x, lightPosition.y, lightPosition.z]}
        shadow-mapSize-width={2048}  // Adjust resolution of the shadow map
        shadow-mapSize-height={2048} // Adjust resolution of the shadow map
     />

    <directionalLight 
        intensity={1.3}
         castShadow
        ref={secondDirectionalLight} 
        targetRef={secondTargetRef.current}
        position={[secondLightPosition.x, secondLightPosition.y, secondLightPosition.z]}
        shadow-mapSize-width={2048}  // Adjust resolution of the shadow map
        shadow-mapSize-height={2048} // Adjust resolution of the shadow map
     />
    <directionalLight 
        intensity={0.4}
         castShadow
        ref={goldLight} 
        targetRef={goldLightTarget.current}
        position={[goldLightPosition.x, goldLightPosition.y, goldLightPosition.z]}
        shadow-mapSize-width={2048}  // Adjust resolution of the shadow map
        shadow-mapSize-height={2048} // Adjust resolution of the shadow map
     />

    {/* MODELS */}
    <primitive 
        castShadow
        receiveShadow
        ref={secondTargetRef}
        object={ chessScene.scene } 
        position={[chessPosition.x, chessPosition.y, chessPosition.z]} 
        rotation={[chessRotation.x, chessRotation.y, chessRotation.z]}  
        scale={13}
    />
    <primitive 
        object={ notebookScene.scene } 
        position={[notebookPosition.x, notebookPosition.y, notebookPosition.z]} 
        rotation={[notebookRotation.x, notebookRotation.y, notebookRotation.z]}  
        scale={22}
    />
     

    <primitive 
        object={ smartphoneScene.scene } 
        position={[smartphonePosition.x, smartphonePosition.y, smartphonePosition.z]} 
        rotation={[smartphoneRotation.x, smartphoneRotation.y, smartphoneRotation.z]}  
        scale={1.8}
    />
    <group position={[logoBookPosition.x, logoBookPosition.y, logoBookPosition.z]} rotation={[logoBookRotation.x, logoBookRotation.y, logoBookRotation.z]} >
        <mesh  ref={logoTextureRef}
            position={[logoPosition.x, logoPosition.y, logoPosition.z]} rotation={[logoRotation.x, logoRotation.y, logoRotation.z]}>
            <planeGeometry 
                args={[2.8, 2.8]}
            />
            <meshStandardMaterial 
            map={logoTexture}
            transparent={true} // Enable transparency
            opacity={3}
            />
        </mesh>

        <primitive 
            object={ notepadScene.scene } 
            position={[notepadPositon.x, notepadPositon.y, notepadPositon.z]} 
            rotation={[notepadRotation.x, notepadRotation.y, notepadRotation.z]}  
            scale={13}
        />
    </group>
    <group position={[goldbarsPosition.x, goldbarsPosition.y, goldbarsPosition.z]} rotation={[goldbarsRotation.x, goldbarsRotation.y, goldbarsRotation.z]}>
        <Clone  receiveShadow
                castShadow
                object={ goldbarScene.scene } 
                position={[goldbarPosition.x, goldbarPosition.y, goldbarPosition.z]} 
                rotation={[goldbarRotation.x, goldbarRotation.y, goldbarRotation.z]}  
                scale={1}
                ref={goldLightTarget}
            >
        </Clone>
        <Clone  receiveShadow
                castShadow
                object={ goldbarScene.scene } 
                position={[secondGoldbarPosition.x, secondGoldbarPosition.y, secondGoldbarPosition.z]} 
                rotation={[secondGoldbarRotation.x, secondGoldbarRotation.y, secondGoldbarRotation.z]}  
                scale={1}
                color="gold"
            />
        </group>

    {/* TABLE */}
    <mesh receiveShadow 
        ref={targetRef}  
        position={[positionTable.x, positionTable.y, positionTable.z]} rotation={[rotationTable.x, rotationTable.y, rotationTable.z]}>
        <planeGeometry 
            args={width < 1240 ? 
                [responsiveSizes.tableSizeS.width, responsiveSizes.tableSizeS.height] 
                 : 
                [responsiveSizes.tableSizeL.width, responsiveSizes.tableSizeL.height]
            }
        />
        <meshStandardMaterial {...texture}/>
    </mesh>

    {/* PHOTOS */}
    <group position={[photosPosition.x, photosPosition.y, photosPosition.z]} rotation={[photosRotation.x, photosRotation.y, photosRotation.z]}>
        <Float rotationIntensity={ 0.3 } speed={infanziaSpeed}>
            <mesh ref={infanziaPhotoRef} position={[
                photosInitialPosition.infanzia.x,
                photosInitialPosition.infanzia.y,
                photosInitialPosition.infanzia.z,
            ]} >
                <planeGeometry 
                    args={width < 1240 ? 
                        [responsiveSizes.photosGeometrySizeS.width, responsiveSizes.photosGeometrySizeS.height] 
                        : 
                        [responsiveSizes.photosGeometrySizeL.width, responsiveSizes.photosGeometrySizeL.height]}
                />
                <meshStandardMaterial map={infanziaTexture} />
            </mesh>
        </Float>
        <Float rotationIntensity={ 0.3 } speed={studiSpeed}>
            <mesh ref={studiPhotoRef} position={[
                photosInitialPosition.studi.x,
                photosInitialPosition.studi.y,
                photosInitialPosition.studi.z,
            ]} rotation-z={0.2}>
                <planeGeometry 
                    args={width < 1240 ? 
                        [responsiveSizes.photosGeometrySizeS.width, responsiveSizes.photosGeometrySizeS.height] 
                        : 
                        [responsiveSizes.photosGeometrySizeL.width, responsiveSizes.photosGeometrySizeL.height]}
                />
                <meshStandardMaterial map={studiTexture}/>
            </mesh>
        </Float>
        <Float rotationIntensity={ 0.3 } speed={mestreSpeed}>
            <mesh ref={mestrePhotoRef} position={[
                photosInitialPosition.mestre.x,
                photosInitialPosition.mestre.y,
                photosInitialPosition.mestre.z,
            ]} rotation-z={-0.8}>
                <planeGeometry 
                    args={width < 1240 ? 
                        [responsiveSizes.photosGeometrySizeS.width, responsiveSizes.photosGeometrySizeS.height] 
                        : 
                        [responsiveSizes.photosGeometrySizeL.width, responsiveSizes.photosGeometrySizeL.height]}
                />
                <meshStandardMaterial map={mestreTexture}/>
            </mesh>
        </Float>
        <Float rotationIntensity={ 0.3 } speed={bivioSpeed}>
            <mesh ref={bivioPhotoRef} position={[
                photosInitialPosition.bivio.x,
                photosInitialPosition.bivio.y,
                photosInitialPosition.bivio.z,
            ]} rotation-z={0.5}>
                <planeGeometry 
                    args={width < 1240 ? 
                        [responsiveSizes.photosGeometrySizeS.width, responsiveSizes.photosGeometrySizeS.height] 
                        : 
                        [responsiveSizes.photosGeometrySizeL.width, responsiveSizes.photosGeometrySizeL.height]}
                />
                <meshStandardMaterial map={bivioTexture}/>
            </mesh>
        </Float>
        <Float rotationIntensity={ 0.3 } speed={careHoldingSpeed}>
            <mesh  ref={careHoldingPhotoRef} position={[
                photosInitialPosition.careHolding.x,
                photosInitialPosition.careHolding.y,
                photosInitialPosition.careHolding.z,
            ]} rotation-z={-0.5}>
                <planeGeometry 
                    args={width < 1240 ? 
                        [responsiveSizes.photosGeometrySizeS.width, responsiveSizes.photosGeometrySizeS.height] 
                        : 
                        [responsiveSizes.photosGeometrySizeL.width, responsiveSizes.photosGeometrySizeL.height]}
                />
                <meshStandardMaterial map={careHoldingTexture}/>
            </mesh>
        </Float>
        <Float rotationIntensity={ 0.3 } speed={madreSpeed}>
            <mesh  ref={madrePhotoRef} position={[
                photosInitialPosition.madre.x,
                photosInitialPosition.madre.y,
                photosInitialPosition.madre.z,
            ]} rotation-z={0.2}>
                <planeGeometry 
                    args={width < 1240 ? 
                        [responsiveSizes.photosGeometrySizeS.width, responsiveSizes.photosGeometrySizeS.height] 
                        : 
                        [responsiveSizes.photosGeometrySizeL.width, responsiveSizes.photosGeometrySizeL.height]}
                />
                <meshStandardMaterial map={madreTexture}/>
            </mesh>
        </Float>
        <Float rotationIntensity={ 0.3 } speed={oroPuroFisicoSpeed}>
            <mesh ref={oroPuroFisicoPhotoRef} position={[
                photosInitialPosition.oroPuroFisico.x,
                photosInitialPosition.oroPuroFisico.y,
                photosInitialPosition.oroPuroFisico.z,
            ]} rotation-z={-0.4}>
                <planeGeometry 
                    args={width < 1240 ? 
                        [responsiveSizes.photosGeometrySizeS.width, responsiveSizes.photosGeometrySizeS.height] 
                        : 
                        [responsiveSizes.photosGeometrySizeL.width, responsiveSizes.photosGeometrySizeL.height]}
                />
                <meshStandardMaterial map={oroPuroFisicoTexture}/>
            </mesh>
        </Float>
        <Float rotationIntensity={ 0.3 } speed={careIsGoldSpeed}>
            <mesh ref={careIsGoldPhotoRef} position={[
                photosInitialPosition.careIsGold.x,
                photosInitialPosition.careIsGold.y,
                photosInitialPosition.careIsGold.z,
            ]} rotation-z={-0.2}>
                <planeGeometry 
                    args={width < 1240 ? 
                        [responsiveSizes.photosGeometrySizeS.width, responsiveSizes.photosGeometrySizeS.height] 
                        : 
                        [responsiveSizes.photosGeometrySizeL.width, responsiveSizes.photosGeometrySizeL.height]}
                />
                <meshStandardMaterial map={careIsGoldTexture}/>
            </mesh>
        </Float>
    </group>
    <Scroll html className="h-screen">
            {/* DOM contents in here will scroll along */}
            {isVisible && <div className="relative left-1/2 mr-32 shadow-background">

                {/* SECTION 1 */}
                <section className="h-screen flex items-center">
                    <p className="text-white text-left text-lg  2xl:text-2xl p-5 2xl:p-14 w-5/12 ms-20 shadow-text ">
                    Sono nato a Campitello di Fassa, un piccolo paesino di montagna in provincia di Trento, e sono figlio di una giovane ragazza madre che, lavorando come cameriera stagionale, si è sempre impegnata con sacrificio e determinazione per mantenermi. <br/>
                    La preoccupazione per la mancanza di soldi era una costante, e questo mi ha insegnato fin da piccolo il valore delle cose semplici e mi ha fatto capire che - nella vita - niente arriva senza fatica. 
                    </p>
                </section>
                
                {/* SECTION 2 */}
                <section className="h-screen flex items-center">
                    <p className="text-white text-left text-lg 2xl:text-2xl p-5 2xl:p-14 w-5/12 ms-20">
                    Terminati gli studi, iniziai a lavorare presso uno studio di geometra, maturando le prime esperienze professionali. Dopo alcuni anni, aprii il mio studio: partii da solo, con determinazione e spirito imprenditoriale, fino a costruire una realtà che in breve tempo mi permise di assumere tre dipendenti. Fu proprio uno di loro ad avviarmi nel settore che avrebbe cambiato la mia vita: il mondo finanziario, in cui entrai con timidezza e riserbo
                    </p>
                </section>

                {/* SECTION 3 */}
                <section className="h-screen flex items-center">
                    <p className="text-white text-left text-lg 2xl:text-2xl p-5 2xl:p-14 w-5/12 ms-20">
                    Tempo dopo mi trasferii a Mestre, per cercare un mercato più vasto. Qui, iniziai un periodo di grandi sacrifici: ero solo, lavoravo 12 ore al giorno e spesso il frigo era vuoto... Un momento della mia vita che ricordo ancora e che mi ha sicuramente temprato.  Amavo ciò che facevo, sentivo che il percorso era quello giusto… anche se è stato tutto in salita, e le difficoltà non sono certo mancate. <br/>
                    Con il tempo, ebbi la possibilità di imparare come creare, gestire e motivare una squadra; così, dopo anni di dura gavetta ma anche di grandi traguardi e importanti risultati, diventai amministratore della SpA italiana di un'importante azienda svizzera

                    </p>
                </section>

                {/* SECTION 4 */}
                <section className="h-screen flex items-center">
                    <p className="text-white text-left text-lg 2xl:text-2xl p-5 2xl:p-14 w-5/12 ms-20">
                    Poi, nel 2008, mi trovai davanti ad un bivio: potevo scegliere di ritirarmi dal mercato, forte di una piena libertà economica, oppure investire la mia esperienza e le mie competenze in qualcosa di nuovo. Avevo già tutto ciò che desideravo dal punto di vista professionale e finanziario, eppure, sentivo che la mia missione non era compiuta. 
                    D’altra parte, la vita si articola in tre fasi: la prima in cui impari il “mestiere”, la seconda in cui metti pratica ciò che hai appreso, e l’ultima, in cui restituisci agli altri tutto quello che hai imparato, per dare loro la stessa possibilità che hai avuto tu. Per me, quel momento era arrivato. 

                    </p>
                </section>

                {/* SECTION 5 */}
                <section className="h-screen flex items-center">
                    <p className="text-white text-left text-lg 2xl:text-2xl p-5 2xl:p-14 w-5/12 ms-20">
                        Così nel 2009, dopo oltre 20 anni di esperienza e un periodo di profonda riflessione, decisi di fondare Care Holding S.p.A.: qualcosa di completamente nuovo e innovativo, creato “dal nulla” grazie a tutte le competenze che avevo acquisito nel tempo… un sogno divenuto realtà.   <br/>
                        Ma c’è una cosa bella dei sogni: non hanno limiti, e il mio non era ancora finito.
                    </p>
                </section>

                {/* SECTION 6 */}
                <section className="h-screen flex items-center">
                    <p className="text-white text-left text-lg 2xl:text-2xl p-5 2xl:p-14 w-5/12 ms-20">
                        Così mi chiesi: come posso continuare a garantire alle persone sicurezza e stabilità, stando al passo con i tempi? La risposta arrivò in uno dei momenti più difficili della mia vita: la perdita di mia madre.  <br/>
                        Scoprii con grande sorpresa che nel corso degli anni aveva accantonato per me un piccolo tesoro in Oro Puro Fisico.
                    </p>
                </section>

                {/* SECTION 7 */}
                <section className="h-screen flex items-center">
                    <p className="text-white text-left text-lg 2xl:text-2xl p-5 2xl:p-14 w-5/12 ms-20">
                    Così, un po’ per curiosità, un po’ perché lavorando in ambito finanziario ne capivo il linguaggio, cominciai ad informarmi su questo mondo. E ne rimasi letteralmente affascinato, colpito, conquistato. Attentissimo alla qualità dei prodotti, capii che l’Oro Puro Fisico è veramente il miglior investimento del mercato. Continuai la ricerca, studiai moltissimo, andai avanti per giorni, settimane, mesi; vissi un’evoluzione personale oltre che professionale. <br />
                    Ed è così, dalla volontà di offrire l’eccellenza del miglior investimento, che è nata Careisgold S.p.A., una realtà grazie alla quale investire nel bene rifugio per eccellenza non è più un lusso per pochi, ma un sogno realizzabile da chiunque. 

                    </p>
                </section>

                {/* SECTION 8 */}
                <section className="h-screen flex flex-col justify-center">
                    <p className="text-white text-left text-lg 2xl:text-2xl p-5 2xl:px-14 w-5/12 ms-20">
                        Non dimenticherò mai le mie radici: le mie umili origini mi hanno insegnato il valore delle piccole cose e la forza di credere nei sogni, anche nei momenti più difficili. Oggi il mio scopo non è solo quello di costruire, ma di restituire: seguire dei valori autentici, mantenere le promesse e dare significato a ogni stretta di mano. Credo fermamente che la parola data faccia ancora la differenza e che il vero successo si costruisca sul benessere condiviso. Sono convinto che le grandi imprese abbiano bisogno di tempo e vadano realizzate insieme, con fiducia e collaborazione autentica. Solo così possiamo creare qualcosa di grande e che duri nel tempo. <br/>
                        È questo il mio scopo, e farò di tutto per perseguirlo, con impegno e dedizione, giorno dopo giorno. <br/>
                        …D’altra parte, questo è solo l’inizio! 

                    </p>
                    <div className="text-white text-left w-5/12 2xl:ms-[8.5rem] ms-[6rem]">
                        <a href="https://careisgold.it" target="_blank" rel="noopener noreferrer" ><button className="progress-button-light border-slate-50 border text-slate-50 px-10 py-5">Visita careisgold.it</button></a>
                    </div>
                </section>
            </div>}

        </Scroll>

</>
}

