import React from 'react';
import { TypeAnimation } from 'react-type-animation';

function LoadingGallery() {


  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <TypeAnimation
      sequence={[
        'Caricamento . . .',
        1000, 
      ]}
      wrapper="span"
      speed={50}
      style={{ fontSize: '1em', display: 'inline-block' }}
      repeat={false}
  />
    </div>
  );
}

export default LoadingGallery;
