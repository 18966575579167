import { useEffect, useRef, useState } from "react";
import GlassEffectBlock from "../../components/GlassEffectBlock";
import Navbar from "../../components/Navbar";
import sfondoMaiMollare from "../../assets/images/Gravity-HardCover-Book-Scene-Free-psd-Mockup.png"
import FooterNew from "../../components/FooterNew";
import primaParte from "../../assets/images/prima-parte.jpg"
import secondaParte from "../../assets/images/seconda-parte.jpg"
import terzaParte from "../../assets/images/terza-parte.webp"
import HalfHalf from "../../components/HalfHalf";

export default function Libri() {
  const [width, setWidth] = useState(window.innerWidth);



  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const titolo = <>
  <span className="text-black text-left">Se sei qui, non è un caso. <br/> questo libro ti ha scelto.</span>
  </>
  const text = <>
    <div className={`text-black text-sm md:${ width < 1600 && width > 425 ? 'text-base' : 'text-lg'}`}>
      <p className="text-left mb-3 ">
        Ti sei chiesto perché 52 cambia-menti? <br/>
        Intendo, perché proprio 52? <br/>
        E cosa significa veramente quel termine “cambia-menti”?
      </p>
      <p className="text-left mb-3 ">
        52 sono le settimane di cui è composto un anno.
        Stiamo per iniziare un nuovo percorso insieme: leggendo un capitolo a settimana, ed affrontando un “cambia-menti” a settimana, darai il tempo alla tua mente di assorbire quanto hai letto, comprenderlo e sedimentarlo fino a farlo diventare un nuovo principio, un nuovo modo di vivere ed affrontare la vita con successo.
      </p>
      <p className="text-left mb-3 ">
        Il mio obiettivo non è quello di cambiarti, perché vai già bene così!
        In queste pagine ho riportato la mia esperienza trentennale nella gestione delle risorse umane, e quello che da sempre mi ha contraddistinto è stata l’umiltà, la voglia di imparare, il desiderio di migliorarmi provando a vedere le cose anche da un altro punto di vista.
      </p>
      <p className="text-left mb-3 ">
        Questo è quello che chiedo anche a te in quest’anno che passeremo insieme.
        I libri sono chiavi verso un mondo diverso.
        Pronti per il viaggio?
      </p>
    </div>
  </>

    
    const capitoliPrimaParte = [
      "1. La Rinascita",
      "2. Accetta il cambiamento",
      "3. Cambia Strategia",
      "4. Fai quello che ti piace",
      "5. Cogli le opportunità",
      "6. Nutri la parte migliore di te",
      "7. Trova il tuo scopo",
      "8. Investi sulla tua formazione",
      "9. Credi in te stesso",
      "10. Liberati dai condizionamenti del tuo passato",
      "11. Ascolta la tua vera natura",
      "12. Differenziati dalla massa",
      "13. Esci dagli schemi",
      "14. Non ascoltare gli altri",
      "15. Accettati per come sei",
      "16. Accetta le critiche",
      "17. Impara a gestire la rabbia",
      "18. Ricorda che l’unione fa la forza",
      "19. Dai valore al tuo tempo",
      "20. Prenditi cura di te",
      "21. Riposa",
      "22. Trova il tuo mentore",
      "23. Liberati dai sensi di colpa"
    ]
    const titoloPrimaParte= <>Prima parte <br /> Migliorare se stessi</>
    const contenutoPrimaParte = <>
      {width >= 740 && <h2 className="text-4xl mb-5 mt-5 2xl:mt-0">Indice:</h2>}
      <ul className="text-left mb-14 w-full pl-10 2xl:pl-28 px-5 grid grid-cols-1 md:grid-cols-2 border-b-2 md:border-0 border-black pb-10 md:pb-0">
        {capitoliPrimaParte.map((capitolo, index) => (
            <li key={index} className="mt-5 lg:mt-8">
                <span className="2xl:text-xl">
                    <p className="font-normal 2xl:font-bold">{capitolo}</p>
                </span>
            </li>
        ))}
      </ul>
    </>

    const capitoliSecondaParte = [
      "24. La Legge di Attrazione",
      "25. Ciò che dai ti torna indietro",
      "26. Il potere creativo della mente",
      "27. Sorridi e il mondo ti sorriderà",
      "28. Il lato positivo",
      "29. Il pensiero strategico",
      "30. Le avversità sono grandi occasioni",
      "31. Mai Mollare",
      "32. Il valore della vita",
      "33. La gioia dell’attesa",
      "34. Inizia dal piccolo e rendilo grande",
      "35. Nulla accade per caso",
      "36. La lista dei 101 desideri"
    ]
    const titoloSecondaParte = <>Seconda parte <br /> Una nuova filosofia di vita</>
    const contenutoSecondaParte = <>
     <ul className="text-left mb-14 w-full pl-10 2xl:pl-28 px-5 grid grid-cols-1 md:grid-cols-2 border-b-2 md:border-0 border-black pb-10 md:pb-0">
        {capitoliSecondaParte.map((capitolo, index) => (
            <li key={index} className="mt-5 lg:mt-8">
                <span className="2xl:text-xl">
                    <p className="font-normal 2xl:font-bold">{capitolo}</p>
                </span>
            </li>
        ))}
      </ul>
    </>

    const capitoliTerzaParte = [
      "37. Condivisione",
      "38. Gratitudine",
      "39. Umiltà",
      "40. Perdono",
      "41. Empatia",
      "42. Sensibilità",
      "43. Perseveranza",
      "44. Resilienza",
      "45. Autostima",
      "46. Passione",
      "47. Qui e Ora",
      "48. Confronto",
      "49. Azione",
      "50. Coraggio",
      "51. Ammirazione",
      "52. Non avere paura"
    ]
    const titoloTerzaParte = <>Terza parte<br />I 16 principi della felicità</>
    const contenutoTerzaParte = <>
     <ul className="text-left mb-14 w-full pl-10 2xl:pl-28 px-5 grid grid-cols-1 md:grid-cols-2 ">
        {capitoliTerzaParte.map((capitolo, index) => (
            <li key={index} className="mt-5 lg:mt-8">
                <span className="2xl:text-xl">
                    <p className="font-normal 2xl:font-bold">{capitolo}</p>
                </span>
            </li>
        ))}
      </ul>
    </>


    return <>
      <div className="" data-aos="fade-in" data-aos-duration="2000">
        <Navbar />
          {/* HERO SECTION */}
          <GlassEffectBlock 
            backgroundImage={sfondoMaiMollare} 
            title={titolo} 
            text={text} 
            // image={libroMaiMollare} 
            buttonText={'Acquista'} 
            secondButtonText={'Leggi il primo capitolo del libro'}
            imgDivClass={` ${width > 2000 ? 'mt-[50rem]' : '2xl:mt-[35rem] mt-[35rem] md:mt-[25rem] xl:mt-[38rem]'}`}
            href='/shop/mai-mollare'
            secondHref='https://claudioboso.it/sfoglia-libro/sfoglialibro.html'
            />
        </div>
          {/* <div className="bg-black h-dvh">
            <div
              id="tolstoy-container"
              style={{ lineHeight: 0, overflow: 'hidden', height: '100%', width: '100%', textAlign: 'center' }}
              className="flex items-center justify-center"
            >
              <iframe
                id="tolstoy"
                src="https://player.gotolstoy.com/g433k4dbr7497?host"
                style={{ width: '100%'}}
                className="h-[100%] p-1"
                scrolling="no"
                frameBorder="0"
                allow="autoplay *; clipboard-write *; camera *; microphone *; encrypted-media *; fullscreen *; display-capture *;"
              ></iframe>
              <script src="https://widget.gotolstoy.com/script.js" defer></script>
            </div>
          </div> */}
        
        {/* PRIMA PARTE */}
        {/* <HalfHalf
          imgOrientation='left'
          backgroundImage={primaParte}
          title={titoloPrimaParte}
          content={contenutoPrimaParte}
        /> */}

        {/* SECONDA PARTE */}
        {/* <HalfHalf
          backgroundImage={secondaParte}
          title={titoloSecondaParte}
          content={contenutoSecondaParte}
        /> */}

        {/* TERZA PARTE */}
        {/* <HalfHalf
          imgOrientation='left'
          backgroundImage={terzaParte}
          title={titoloTerzaParte}
          content={contenutoTerzaParte}
        /> */}


        <div className="py-10 bg-white">
          <div className="uppercase py-7">
            <h3 className="text-4xl xl:text-5xl">Indice</h3>
          </div>
          <div className="flex flex-col md:flex-row md:justify-around md:px-10">
            <div className="border-b-2 md:border-0 border-black pb-10 md:pb-0">
              <div className="px-10 ">
                <h3 className="py-5 font-bold text-xl md:text-2xl xl:text-3xl">{titoloPrimaParte}</h3>
                <ul>
                  {capitoliPrimaParte.map((capitolo, index) => (
                    <li key={index} className="mt-3 text-left">
                        <span className={`text-sm md:${ width < 1600 && width > 425 ? 'text-base' : 'text-lg'}`}>
                            <p className="font-normal ">{capitolo}</p>
                        </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="border-b-2 md:border-0 border-black pb-10 md:pb-0">
              <div className="px-10 pt-10 md:pt-0">
                <h3 className="py-5 font-bold text-xl md:text-2xl xl:text-3xl">{titoloSecondaParte}</h3>
                <ul>
                  {capitoliSecondaParte.map((capitolo, index) => (
                    <li key={index} className="mt-3 text-left">
                        <span className={`text-sm md:${ width < 1600 && width > 425 ? 'text-base' : 'text-lg'}`}>
                            <p className="font-normal ">{capitolo}</p>
                        </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="pb-10 md:pb-0">
              <div className="px-10 pt-10 md:pt-0">
                <h3 className="py-5 font-bold text-xl md:text-2xl xl:text-3xl">{titoloTerzaParte}</h3>
                <ul>
                  {capitoliTerzaParte.map((capitolo, index) => (
                    <li key={index} className="mt-3 text-left">
                        <span className={`text-sm md:${ width < 1600 && width > 425 ? 'text-base' : 'text-lg'}`}>
                            <p className="font-normal ">{capitolo}</p>
                        </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      <FooterNew/>
    </>
}