import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import axios from "axios";
import HomePage from './pages/HomePage';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import Storia from './pages/Storia';
import Libri from './pages/Libri';
import Gallery from './pages/Gallery';
import ShopMaiMollare from './pages/ShopMaiMollare.jsx';
import ScrollToTop from './components/ScrollToTop.jsx'
import Notizie from './pages/RassegnaStampa.jsx';
import RassegnaStampa from './pages/RassegnaStampaSingle.jsx';
import MaiMollare from '../src/pages/libri/MaiMollare.jsx'
import ReMida from '../src/pages/libri/ReCaio.jsx';
import ShopReMida from './pages/ShopReMida.jsx';
import { mountVercelToolbar } from '@vercel/toolbar';

  
AOS.init();


function App() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    mountVercelToolbar();
    const fetchData = async () => {
      try {
        const res = await axios.get(`https://newgest.careholding.it/blog_ws.axd/?type=GetPosts`,{
              port: 8800
            });
        setPosts(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  function formatToUrlFriendly(text) {
    const cleanedText = text
      .toLowerCase() // Convert to lowercase
      .replace(/ /g, '-') // Replace spaces with hyphens
      .replace(/[àáâãäå]/g, 'a') // Replace "à", "á", "â", "ã", "ä", "å" with "a"
      .replace(/[èéêë]/g, 'e') // Replace "è", "é", "ê", "ë" with "e"
      .replace(/[ìíîï]/g, 'i') // Replace "ì", "í", "î", "ï" with "i"
      .replace(/[òóôõö]/g, 'o') // Replace "ò", "ó", "ô", "õ", "ö" with "o"
      .replace(/[ùúûü]/g, 'u') // Replace "ù", "ú", "û", "ü" with "u"
      .replace(/[^a-z0-9-_+]/g, '-') // Remove special characters except hyphens and alphanumeric characters
      .replace(/-{2,}/g, '-'); // Remove consecutive hyphens

        if (cleanedText.endsWith('-')) {
            return cleanedText.slice(0, -1);  
    }return cleanedText;
  }

  return (
    <div className="App">
      <Router>
        <ScrollToTop/>
        <Routes>
          <Route exact path="/" element={<HomePage />} />    
          <Route exact path="/storia" element={<Storia />} />    
          <Route exact path="/libri" element={<Libri />} />  
          <Route exact path="/libri/mai-mollare" element={<MaiMollare />} />  
          <Route exact path="/libri/re-mida" element={<ReMida />} />    
          <Route exact path="/gallery" element={<Gallery />} />    
          <Route exact path="/articoli" element={<Notizie />} />    

          <Route exact path="/shop/mai-mollare" element={<ShopMaiMollare/>} />    
          <Route exact path="/shop/re-mida" element={<ShopReMida/>} />    
          {posts.map(post => (
                <Route key={post.Id} exact path={`/notizie/rassegna-stampa/${post.Id}/${formatToUrlFriendly(post.Metatitle)}/`} element={<RassegnaStampa/>} />
            ))}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
