import { useCallback, useEffect, useRef, useState } from "react";
import Navbar from "../components/Navbar";
import { Helmet } from "react-helmet";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
// gallery images
import img1 from '../assets/gallery/1.jpg';
import img2 from '../assets/gallery/2.jpg';
import img3 from '../assets/gallery/3.jpg';
import img4 from '../assets/gallery/4.jpg';
import img5 from '../assets/gallery/5.jpg';
import img6 from '../assets/gallery/6.jpg';
import img7 from '../assets/gallery/7.jpg';
import img8 from '../assets/gallery/8.jpg';
import img9 from '../assets/gallery/9.jpg';
import img10 from '../assets/gallery/10.jpg';
import img11 from '../assets/gallery/11.jpg';
import img12 from '../assets/gallery/12.jpg';
import img13 from '../assets/gallery/13.jpg';
import img14 from '../assets/gallery/14.jpg';
import FooterNew from "../components/FooterNew";
// tolstoy
import TolstoyCarouselWidget from "../components/TolstoyCarousel";
import LoadingGallery from "../components/LoadingGallery";
import DefaultButton from "../components/DefaultButton";

// Utility function to get image dimensions
const getImageDimensions = (src) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      resolve({ width: img.naturalWidth, height: img.naturalHeight });
    };
  });
};

export default function GalleryGrid() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Manage the loading state
  const [loading, setLoading] = useState(true);
  const [fotoVideo, setFotoVideo] = useState(true)
  // const tolstoyContainerGalleryRef = useRef(null); 

  
  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const handleEndLoading = () => {
    setTimeout(() => {
      setIsLoading(false);
      setTimeout(() => {
        setLoading(false);
      },1000)
    },2000)
  }


  useEffect(() => {
    const imageSources = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14];
    // Fetch dimensions for each image
    const loadImages = async () => {
      const promises = imageSources.map(async (src) => {
        const { width, height } = await getImageDimensions(src);
        return { src, width, height };
      });

      const loadedPhotos = await Promise.all(promises);
      setPhotos(loadedPhotos);
    };

    loadImages();
    handleEndLoading()

  }, []);

  

  function toggleFotoVideo() {
    setFotoVideo(!fotoVideo)
  }
  return (
    <div>

      <Navbar />
        {loading &&
          <div className={`fixed inset-0 flex items-center justify-center bg-black z-40 ${!isLoading && 'fade-out-experience'}`}>
            <div className="absolute text-white text-2xl font-bold z-40">
              <LoadingGallery />
            </div>
          </div>
        }

        <div className="mt-32">
          <div className="text-white md:p-10 xl:w-1/2 mx-auto">
            <h2 className="lg:text-5xl text-4xl">Galleria video e foto</h2>
            <p className="py-10 text-xl">Scorri la gallery e scopri i momenti più significativi del percorso di Claudio Boso e dei progetti di Careisgold, dove passione e impegno si trasformano in successi.</p>
          </div>
          <div  className="flex justify-center space-x-5 mb-10">
            <button 
              className={`${fotoVideo ? 'bg-white text-black' : 'text-slate-50'} page-link  border-slate-50  px-8 py-2 uppercase border text-center transition duration-300 ease-in-out w-5/12 md:w-1/5`}
              onClick={() => setFotoVideo(true)}
            >
              VIDEO
            </button>
            <button 
                className={`${!fotoVideo ? 'bg-white text-black' : 'text-slate-50'} page-link  border-slate-50 px-8 py-2 uppercase border text-center transition duration-300 ease-in-out w-5/12 md:w-1/5`}
                onClick={() => setFotoVideo(false)}
            >
              FOTO
            </button>
          </div>
           
        </div>

        {fotoVideo ? 
        <>
          <TolstoyCarouselWidget /> 
          <div className="flex justify-center flex-col items-start max-w-6xl mx-auto mt-10 mb-20 px-5 xl:px-0">
            {/* <div className=""> */}
              <iframe 
                src="https://www.youtube.com/embed/GgBZlmXIXZM?si=Cb-m25IT4j6qPkyc" 
                allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media" 
                width="100%"
                title="TGT lungo 14 min 12"
                className='h-[14rem] md:h-[45rem]'>
              </iframe>
            {/* </div> */}
          </div>
        </>
        :
        <>
          <Gallery 
          photos={photos} 
          // direction={"column"}
          columns={3}
          onClick={openLightbox}
          />
          <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={photos.map(x => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title
                }))}
                components={{
                  View: ({ data, currentIndex }) => (
                    <div className="react-images__view-image">
                      <img src={data.src} alt={data.caption} />
                    </div>
                  ),
                }}
              />
            </Modal>
          ) : null}
          </ModalGateway>
          </>
         }
         
          {/* <FooterNew/> */}
    </div>

  );
}
