import { Canvas } from "react-three-fiber";
import Navbar from "../components/Navbar";
import { useEffect, useRef, useState } from 'react';
import { Loader,  ScrollControls, } from "@react-three/drei";

import * as THREE from 'three'
import { IoMdVolumeHigh, IoMdVolumeOff } from "react-icons/io";
import mySound from '../assets/sounds/Hans_Zimmer_-_Honor_Him_(Hydr0.org).mp3';
import Experience3 from "../components/Experience3";
import StoriaMobile from "../components/StoriaMobile";
import Loading3DText from "../components/Loading3DText";


export default function Storia() {
  // SOUND ------------
  const [showSoundButton, setShowSoundButton] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [sound, setSound] = useState(null);
  const listener = useRef(new THREE.AudioListener());
  const startTime = useRef(0); // Start time of the audio
  const pauseTime = useRef(0); // Time at which the audio was paused
  // LOADING STATE
  const [isLoading, setIsLoading] = useState(true); // Manage the loading state
  const [loading, setLoading] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  // SOUND LOGIC  
  useEffect(() => {
    const audioLoader = new THREE.AudioLoader();
    const audio = new THREE.Audio(listener.current);
    setSound(audio);

    audioLoader.load(
      mySound,
      (buffer) => {
        console.log('Audio loaded successfully');
        setShowSoundButton(true);
        audio.setBuffer(buffer);
        audio.setLoop(true);
        audio.setVolume(0.1); // Default volume
        startTime.current = audio.context.currentTime; // Record when the audio starts
        audio.play();
        // Check for AudioContext suspended state and log a message
        if (audio.context.state === "suspended") {
          audio.stop();
          setIsMuted(true);
        }
      },
      undefined,
      (error) => {
        console.error('Error loading audio:', error);
      }
    );

    return () => {
      if (audio) {
        audio.stop(); // Stop the audio playback
        audio.disconnect(); // Disconnect the audio to free up resources
        audio.buffer = null; // Clear the buffer to release memory
      } 
    };
  }, []);

  useEffect(() => {
    if (sound) {
      sound.setVolume(isMuted ? 0 : 0.1); // Just update the volume
    }
  }, [isMuted, sound]); // Only update volume when mute state changes


  // Toggle mute/unmute without resetting sound playback position
  const toggleSound = () => {
      if (!isMuted) {
        pauseTime.current = sound.context.currentTime - startTime.current; // Record the pause time
        sound.stop(); // Stop the audio without resetting the buffer
      } else {
        // Resume from pause time
        sound.play();
        sound.offset = pauseTime.current;
        startTime.current = sound.context.currentTime - pauseTime.current;
      }
    setIsMuted((prevState) => !prevState); // Toggle mute state
  };

  const handleEndLoading = () => {
    setIsLoading(false);
    setTimeout(() => {
      setLoading(false);
    },3000)
  }

    return <>
            {/* Sound Toggle Button */}
            {showSoundButton && 
              <button
                style={{
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  color: '#fff',
                  border: 'none',
                  padding: '10px',
                  cursor: 'pointer',
                  zIndex: 9999, // Ensure it's on top
                }}
                className="fixed bottom-4 left-4 rounded-full"
                onClick={toggleSound}
              >
                {isMuted ? <IoMdVolumeOff /> : <IoMdVolumeHigh />}
              </button>
            }

    {width < 800 ? (<StoriaMobile/> )
        :
    <div className='storia-experience'>
        <Navbar />
        {loading &&
          <div
            className={`bg-black text-white fixed h-screen w-full z-40 ${isLoading ? 'fade-in-experience' : 'fade-out-experience'}`}
          >
            <div className="flex items-center justify-center h-screen w-full">
            <Loading3DText handleStartExperience={handleEndLoading} />
            </div>
          </div>
        }
        <>
           <Canvas shadows gl={{ antialias: true, depth: true }} >
            <ScrollControls pages={8} damping={0.5}>
              <Experience3 startExperience={loading} />
            </ScrollControls>
          </Canvas>
          </>
        <Loader/>
      </div>
      }
    </>
}