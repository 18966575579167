import { useState, useEffect } from "react";
import DefaultButton from "../components/DefaultButton.jsx"

export default function ZoomInOut({ images, title, text, buttonText, href, buttonClass, firma }) {
    const [zoom, setZoomIn] = useState(false);  // Start with 'zoom-in'
    const [index, setCurrentIndex] = useState(0);
    const [fade, setFade] = useState(true);
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
        setWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const quotes = text;

    useEffect(() => {    
        // Initial zoom-out after a short delay
        // setTimeout(() => {
            setZoomIn(true);
        // }, 5);
    
        const interval = setInterval(() => {
            // Start fade-out
            setFade(false);
    
            // Wait for fade-out to finish
            setTimeout(() => {
                setCurrentIndex(prev => (prev === images.length - 1 ? 0 : prev + 1));
    
                // Start fade-in of the new image only after the image is set
                setTimeout(() => {
                    setFade(true);
                    setZoomIn(prevZoom => !prevZoom);  // Toggle zoom after the fade-in starts
                }, 500);  // Small delay to ensure fade-in happens after image change
    
            }, 1000);  // Wait for fade-out to complete before changing the image
    
        }, 10000);  // Interval between image changes

        return () => clearInterval(interval);
    }, [images.length]);

    return (
        <>
            <div className="relative overflow-hidden h-svh ">
                <div className='h-screen opacity-80'>
                    <img src={images[index]} alt="" className={`${zoom ? 'zoom-in' : 'zoom-out'} ${fade ? 'fade-in' : 'fade-out'} transition-all absolute top-0 left-0 w-full h-full object-cover `} />
                </div>
                <div className={`absolute bottom-[1rem] 2xl:bottom-[2rem] md:left-[1rem] 2xl:left-[3rem] md:w-[40rem] 2xl:w-[55rem] ${fade ? 'fade-in' : 'fade-out'}`}>
                    {title && <h1 className={` mb-10 p-5 font-bold text-white text-center text-lg `}>
                        <span className="focus-in"> {title} </span>
                    </h1>}
                    <div className={`text-white text-start text-[1rem] md:text-2xl 2xl:text-4xl p-4 md:p-6 2xl:p-10 tracking-wider`}>
                        <p className="uppercase mb-2 font-bold ">{quotes[index].title}</p>
                        <p className="text-balance">{quotes[index].subtitle} </p>
                        {index === 0 &&  <img src={firma} alt=""  className="mt-3 ml-32 w-[35%] 2xl:w-[45%]"/>}
                    </div>
                    {index === 1 && 
                        <div className={`${fade ? 'fade-in' : 'fade-out'} text-left`}>
                            <DefaultButton text={buttonText || 'Scopri di più'} link={href} dark={true} buttonClass={buttonClass} />
                        </div>
                    }
                </div>
            </div>
        </>
    );
}
