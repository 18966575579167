import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { IoClose } from "react-icons/io5";
import { RxHamburgerMenu } from "react-icons/rx";
import firma from "../assets/images/firmaGold.png"

function Navbar() {

    const [isVisible, setIsVisible] = useState(true);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [menuTransition, setMenuTransition] = useState(false);
    const [hideLink, setHideLink] = useState(false);
    const [negativeClass, setNegativeClass] = useState('text-negative');

    const location = useLocation();
    const isActive = (path) => location.pathname === path;

    let lastScrollY = window.scrollY;
    
    useEffect(() => {
        const handleScroll = () => {
            const isSmallDevice = window.innerWidth < 768;
            if (!isMenuOpen) {
                setIsVisible(window.scrollY < lastScrollY);
                lastScrollY = window.scrollY;
            }

            if (window.scrollY < 800) {
                setIsVisible(true)
            }
            
            if ((window.scrollY > 850 && !isSmallDevice) || (window.scrollY > 710 && isSmallDevice)) {
                setNegativeClass('text-negative');
            } else {
                // setNegativeClass('text-negative-remove');
            }
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => window.removeEventListener('scroll', handleScroll);
    }, [isMenuOpen]);

    useEffect(() => {
        document.body.style.overflow = isMenuOpen ? 'hidden' : 'auto';
    }, [isMenuOpen]);


    const handleMenuOpen = () => {
        setIsMenuOpen(true);
        setHideLink(false);
        setTimeout(() => {
            setMenuTransition(true);
        }, 500);
    };

    const handleMenuClose = () => {
        setHideLink(true);
        setTimeout(() => {
            setMenuTransition(false);
            setIsMenuOpen(false);
        }, 450);
    };

    return (
    <>
    {/* <div className={`text-negative-remove fixed z-50 top-0 left-0 w-full transition-transform duration-300 ${isVisible ? 'translate-y-0' : '-translate-y-full'}`}>
            <div className={`flex justify-between items-center ${negativeClass } p-8 md:p-5`}>
                <img src={firma} alt="logo" className='w-32 md:w-56 z-40'/>
            </div>
        </div> */}
        <div className={`${negativeClass} fixed z-50 top-0 left-0 w-full transition-transform duration-300 ${isVisible ? 'translate-y-0' : '-translate-y-full'}`}>
            <div className={`flex xl:justify-center justify-end items-center p-7`}>
                {/* <img src={firma} alt="" className={`w-[10%]`}/> */}
                <div className="hidden xl:block">
                    <nav className="flex justify-end items-center ">
                        <Link to="/" className={`uppercase panoragraf-light font-bold mx-6 ${isActive('/') ? 'underline-animate-active' : 'underline-animate'}`}>Home</Link>
                        <Link to="/storia/" className={`uppercase panoragraf-light font-bold mx-6 ${isActive('/storia/') ? 'underline-animate-active' : 'underline-animate'}`}>La mia storia</Link>
                        <Link to="/libri/" className={`uppercase panoragraf-light font-bold mx-6 ${isActive('/libri/') ? 'underline-animate-active' : 'underline-animate'}`}>I miei libri</Link>
                        <Link to="/articoli/" className={`uppercase panoragraf-light font-bold mx-6 ${isActive('/articoli/') ? 'underline-animate-active' : 'underline-animate'}`}> articoli</Link>
                        <Link to="/gallery/" className={`uppercase panoragraf-light font-bold mx-6 ${isActive('/gallery/') ? 'underline-animate-active' : 'underline-animate'}`}>gallery</Link>
                    </nav>
                </div>
            </div>
        </div>
        <div className={`block xl:hidden fixed z-50 top-0 left-0 w-full transition-transform duration-300 ${isVisible ? 'translate-y-0' : '-translate-y-full'}`}>
            <div className={`flex xl:justify-center justify-end items-center text-slate-50 p-10`}>
                <div className="">
                    <button className="w-6 h-6" onClick={handleMenuOpen}>
                        <div className=''><RxHamburgerMenu className='text-3xl text-gold'/></div>
                    </button>
                    <nav className={`z-50 flex flex-col justify-top items-center absolute top-0 right-0 w-full h-full bg-black transition-all duration-[500ms] ease-in ${isMenuOpen ? 'opacity-100' : 'opacity-0'} ${isMenuOpen ? 'translate-y-0' : '-translate-y-full'} ${isMenuOpen && 'h-screen'}`}>
                        {menuTransition && (
                            <>
                                <Link to="/" data-aos="fade-right" data-aos-duration="250" className={`${hideLink ? 'link-hidden' : ''} text-slate-50 uppercase mx-6 mb-2 mt-36 ${isActive('/') ? 'text-gold' : ''}`}>Home</Link>
                                <Link to="/storia/" data-aos="fade-right" data-aos-duration="500" className={`${hideLink ? 'link-hidden' : ''} text-slate-50 uppercase mx-6 my-2 ${isActive('/storia/') ? 'text-gold' : ''}`}>storia</Link>
                                <Link to="/libri/" data-aos="fade-right" data-aos-duration="750" className={`${hideLink ? 'link-hidden' : ''} text-slate-50 uppercase mx-6 my-2 ${isActive('/libri/') ? 'text-gold' : ''}`}>I miei libri</Link>
                                <Link to="/articoli/" data-aos="fade-right" data-aos-duration="750" className={`${hideLink ? 'link-hidden' : ''} text-slate-50 uppercase mx-6 my-2 ${isActive('/articoli/') ? 'text-gold' : ''}`}>articoli</Link>
                                <Link to="/gallery/" data-aos="fade-right" data-aos-duration="1000" className={`${hideLink ? 'link-hidden' : ''} text-slate-50 uppercase mx-6 my-2 ${isActive('/gallery/') ? 'text-gold' : ''}`}>gallery</Link>
                                <button className="absolute top-0 right-0 w-6 h-6 text-white m-10" onClick={handleMenuClose}>
                                    <IoClose className='text-3xl text-mcare'/>
                                </button>
                            </>
                        )}
                    </nav>
                </div>
            </div>
        </div>
    </>
    );
}

export default Navbar;
