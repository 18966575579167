import { useEffect, useRef } from "react";
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";
import firmaClaudio from '../assets/images/firmaGold.png'

export default function FooterNew() {
    const lineRef = useRef(null);

    useEffect(() => {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.2 // Trigger when 50% of the element is in viewport
      };
    
      const lineElement = lineRef.current; // Store the current element reference
  
      const observer = new IntersectionObserver((entries) => {
  
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Add animation class when element is in viewport
            lineElement.classList.add('animated-line');
          } else {
            // Remove animation class when element is out of viewport
            lineElement.classList.remove('animated-line');
          }
        });
      }, options);
    
      // Start observing the line element
      observer.observe(lineElement);
    
      // Clean up observer when component unmounts
      return () => {
        // Check if the lineElement is still valid before unobserving
        if (lineElement) {
          observer.unobserve(lineElement);
        }
      };
    }, []);

    return <>
     <footer className="md:w-full bg-black text-white md:py-16 pt-10 pb-16 shadow-footer ">
            <div className="w-full flex justify-center items-center text-center z-20 pb-10 ">
                <a href="https://www.linkedin.com/in/claudio-boso/" target='blank' className={`text-4xl px-3 md:m-0 text-gold hover:text-amber-300 `} ><FaLinkedin /></a>
                <a href="https://www.youtube.com/channel/UCnDZMHuQbSdSmS5PlJTU2bQ" target='blank' className={`text-4xl px-3 md:m-0 text-gold hover:text-amber-300 `} ><FaYoutube /></a>
                <a href="https://www.facebook.com/boso.claudio/" target='blank' className={`text-4xl px-3 md:m-0 text-gold hover:text-amber-300 `} ><FaFacebook /></a>
                <a href="https://www.instagram.com/bosoclaudio/" target='blank' className={`text-4xl px-3 md:m-0 text-gold hover:text-amber-300 `} ><FaInstagram /></a>
            </div>
            <div className="w-full z-20">
                <div className={`text-center w-80 md:w-1/2 mx-auto flex flex-col justify-center items-center`}>
                    {/* <img src={firmaClaudio} alt=""  className="w-1/2 lg:w-2/5 pb-8"/> */}
                    <p className='text-lg'>Via Monte Baldo 14/D, Villafranca di Verona (Loc. Calzoni), Verona, Italia</p>
                    <p className='text-lg'>Tel: +39 045 8213155</p>
                    <p className='text-lg'>Lunedì - Venerdì<br />9:00-12:30 - 14:00-17:00</p>
                    <a className="link link-contrast link-secondary text-lg  text-gold hover:text-amber-300" href="mailto:#">info@claudioboso.it</a>

                    <hr ref={lineRef} className="border-[#CE9E59] my-4" />

                    <div className="flex flex-wrap justify-center md:space-x-8">
                        <a href='https://www.iubenda.com/privacy-policy/29730421' target='_blank' rel="noopener noreferrer" className={`text-sm me-3 mt-3 md:m-0  text-gold hover:text-amber-300 `} >Privacy Policy</a>
                        <a href='https://www.iubenda.com/privacy-policy/29730421/cookie-policy' target='_blank' rel="noopener noreferrer" className={`text-sm me-3 mt-3 md:m-0  text-gold hover:text-amber-300`} >Cookie Policy</a>
                    </div>                
                </div>
            </div>
        </footer>
    </>
}