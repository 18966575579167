import DefaultButton from "./DefaultButton";

export default function GlassEffectBlock({backgroundImage, title, text, image, buttonText, secondButtonText, href, secondHref, imgDivClass}) {



    return <>
        <div className="md:flex">
            <div className="xl:w-2/5 md:w-1/2 bg-slate-100 h-screen overflow-hidden  content-center">
                <div className="lg:p-6 xl:p-8 p-[1.2rem] md:mt-10 2xl:mt-0">
                    <h1 className="text-sm md:text-lg xl:text-xl 2xl:text-2xl mb-4 2xl:mb-10 font-bold text-left uppercase mt-14 sm:mt-0 ">{title}</h1>
                    <section className="text-xs md:text-sm lg:text-sm 2xl:text-lg tracking-wider  ">{text}</section>
                </div>
            </div>
            <div className="xl:w-3/5 md:w-1/2">
                <div 
                    className='h-screen overflow-hidden flex justify-center items-center' 
                    style={{ 
                        backgroundImage: `url(${backgroundImage})`, 
                        backgroundSize: 'cover', 
                        backgroundPosition: 'top', 
                        backgroundRepeat: 'no-repeat' 
                    }}
                >
                    <div className={`w-[25rem] ${imgDivClass}`}>
                        <img src={image} alt="" />
                        <div className={`p-3 mb-5`}>
                            <DefaultButton text={buttonText || 'Scopri di più'} addClass='font-bold w-[70%]' dark link={href}/>
                            {secondButtonText &&  <DefaultButton text={secondButtonText || 'Scopri di più'} addClass='font-bold w-full mt-5 text-sm md:text-base' dark link={secondHref}/>}
                        </div>
                    </div>
                </div>
            </div>
        </div>


        {/* <div className="relative">
            <div className='w-full h-screen overflow-hidden relative '>
                <img src={backgroundImage} alt="" className={` w-full`} />
            </div>
            <div className="absolute top-[12rem] left-[10rem]  w-1/3">
                <h1 className="text-lg mb-10 p-5 font-bold text-white text-center glass-effect uppercase">{title}</h1>
                <section className="text-lg text-white font-bold font-sans p-10 tracking-wider glass-effect ">{text}</section>
            </div>
            <div className="absolute right-[10rem] top-[11rem] w-1/4 glass-effect">
                <img src={image} alt="" />
                <div className="p-3">
                    <DefaultButton text={buttonText || 'Scopri di più'} addClass='font-bold' link={href}/>
                </div>
            </div>
        </div> */}
    </>
}