import Navbar from "../components/Navbar";
import FooterNew from "../components/FooterNew";
import libro from "../assets/images/Mockup_ReMida.jpg"
import amazonLogo from "../assets/images/amazonKindle.png"

export default function ShopReMida() {
    return<>
      <div className="" data-aos="fade-in" data-aos-duration="2000">
        <Navbar />
            <section className="flex flex-col md:flex-row md:h-screen mb-20 md:mb-0">
                <div className="w-[100%] md:w-[50%] p-20 md:p-0 bg-white flex justify-center">
                    <img src={libro} alt="" className="object-cover"/>
                </div>
                <div className="w-[100%] md:w-[50%] mt-7 md:mt-0 bg-black text-white flex justify-center">
                    <div className="self-center text-md 2xl:text-lg w-[70%] text-slate-200 mt-16 2xl:mt-0">
                        <ul>
                            <li className="flex justify-between mb-3">
                                <span className="font-bold">Titolo:</span><span> Investire con Re Mida</span> 
                            </li>
                            <li className="flex justify-between mb-3">
                                <span className="font-bold">Autore:</span> Carolina Guerrini
                            </li>
                            <li className="flex justify-between mb-3">
                                <span className="font-bold">Pagine:</span> 152
                            </li>
                            <li className="flex justify-between mb-3">
                                <span className="font-bold">ISBN:</span> 979-1222930114
                            </li>
                        </ul>
                        <hr className="pb-5 2xl:py-4 w-[120%] -mx-[10%]"/>
                        <ul>
                            <li className="flex justify-between mb-3">
                                <span className="font-bold">Prezzo listino:</span><span>22,50 €</span>
                            </li>
                            <li className="flex justify-between mb-3">
                                <span className="font-bold">Prezzo di spedizione:</span><span className="line-through">5,00 €</span> 
                            </li>
                        </ul>
                        <hr  className="pb-5 2xl:py-4 w-[120%] -mx-[10%]"/>
                        <ul>
                            <li className="flex justify-between mb-3">
                                <span className="font-bold">Totale:</span> <span className="line-through">27,50 €</span> 
                            </li>
                            <li className="flex justify-between mb-3">
                                <span className="font-bold"></span> <span >22,50 €</span> 
                            </li>
                        </ul>
                        <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                        <input type="hidden" name="cmd" value="_s-xclick" />
                        <input type="hidden" name="hosted_button_id" value="6F3S9V4SFX9NN" />
                        <input type="hidden" name="currency_code" value="EUR" />
                        <input type="image" src="https://www.paypalobjects.com/it_IT/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" title="PayPal, il metodo semplice e sicuro per pagare online" alt="Acquista ora" />
                        </form>
                            <a href="https://www.amazon.it/Investire-Re-Mida-ritorno-famiglie-ebook/dp/B0DTV3CFWH/ref=sr_1_1?crid=2Q309U9K81SG&dib=eyJ2IjoiMSJ9.Y9u4eVoVbX9v61XSd_7oPQ.QKnCqkmSqP9opjFIjoMlTFm95HdoivAABjBxu8SR108&dib_tag=se&keywords=investire+con+re+mida&qid=1740755945&sprefix=investire+con+re+%2Caps%2C91&sr=8-1" target="_blank"  rel="noreferrer" className="text-black ">
                                <div className="bg-slate-100 hover:bg-slate-300 rounded mt-9 flex justify-center">
                                    <img src={amazonLogo} alt="amazon kindle logo" className="p-2"/>
                                </div>
                            </a>
                    </div>

                </div>
            </section>
        <FooterNew/>
        </div>
    </>
}