import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import ZoomInOut from '../components/ZoomInOut';
import ClaudioBosoMargine from '../assets/images/Claudio_2.jpg'
import ClaudioBosoMobile from '../assets/images/ClaudioBoso.jpg'
import ClaudioBoso2 from '../assets/images/ClaudioBoso-3.jpg'
import ClaudioBosoFigliMargine from '../assets/images/Claudio-figli_2.jpg'
import ClaudioBosoFigliMobile from '../assets/images/0046-BosoFamily.jpg'
import MaiMollare from '../assets/images/Books-Mockup_1.jpg'
import MinimalSection from '../components/MinimalSection';
import FooterNew from '../components/FooterNew';
import firmaBiancaClaudio from '../assets/images//firmaBianca.png';
import { Link } from 'react-router-dom';


function HomePage() {

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const heroImages = [ClaudioBosoMargine, MaiMollare, ClaudioBosoFigliMargine]
  const heroImagesMobile = [ClaudioBosoMobile, MaiMollare, ClaudioBosoFigliMobile]
  const quotes = [
    {
      title: "Non esistono obiettivi impossibili.",
      subtitle: "Esistono solamente obiettivi che non ci interessano abbastanza."
    },
    {
      title: "La mia filosofia: Mai Mollare!",
      subtitle: "Ogni ostacolo è solo un passo verso la vittoria."
    },
    {
      title: "La nostra unione è la nostra forza.",
      subtitle: "Solo uniti possiamo raggiungere traguardi che da soli sembrano impossibili."
    }
  ]
  const description = <>
    <p className='text-justify'> 
    Sono Claudio Boso, Fondatore e Presidente del Gruppo Care Holding S.p.A., da cui è nata Careisgold S.p.A.
    </p>
    <p className='mt-5 text-justify'> 
    Il mio è stato un percorso ricco di sfide, personali e professionali, che mi ha insegnato come <b>il vero talento non risieda in capacità “straordinarie”, ma… nella perseveranza</b>: la capacità di rialzarsi sempre, anche quando sembra impossibile. Da questa consapevolezza è nato <b>il mio mantra “mai mollare”</b>, una filosofia che mi accompagna nella mia quotidianità e che ho approfondito nell’omonimo <Link className="text-blue-700" to='/libri/'>libro</Link>.
    </p>
    <p className='mt-5 text-justify'> 
    Negli anni, ho avuto l'onore di accompagnare molti professionisti verso il successo.     
    </p>
    <p className='mt-5 text-justify'> 
    La chiave? <b>Trasmettere concetti complessi in modo semplice e chiaro</b>. Come diceva Einstein, infatti, “quando riesci a spiegare ad un bambino di 6 anni il tuo progetto, e lo capisce, hai vinto!”. 
    </p>
    <p className='mt-5 text-justify'> 
    Ma c’è anche un altro elemento fondamentale: l’intelligenza emotiva. 
    </p>
    <p className='mt-5 text-justify'> 
    <b>Saper ascoltare veramente gli altri, non solo con l’udito, ma con empatia e attenzione, è una vera abilità</b>, e solo chi la padroneggia riesce a far emergere il potenziale delle persone e delle opportunità che incontrano lungo il cammino. 
    </p>
    <p className='mt-5 text-justify'> 
    Con <b>Care Holding SpA</b>, ho realizzato un grande sogno: <b>ho dato vita ad una realtà capace di garantire una protezione completa e diversificata per il capitale e il futuro delle persone, che, grazie alla dinamicità e alla sinergia tra le sue diverse consociate, permette una tutela a 360 gradi</b>. Ma se c’è una cosa bella dei sogni, è che non hanno limiti. Per questo, <b>con Careisgold SpA ho voluto fare qualcosa di più e creare qualcosa di rivoluzionario: rendere accessibile a tutti il bene rifugio per eccellenza, l’Oro Puro Fisico.</b>
    </p>
    <p className='mt-5 text-justify'> 
    Sono convinto che il futuro si costruisca attraverso perseveranza, chiarezza e, soprattutto, la forza di una squadra unita: possiamo fare tanto da soli, ma il vero successo arriva quando ci circondiamo di persone migliori di noi. Saper <b>creare una squadra in cui ognuno può dare il meglio è ciò che trasforma i sogni in realtà.</b>
    </p>
  </>
  
  return (
    <>
      <div className="" data-aos="fade-in" data-aos-duration="3000">
        <Navbar />
        {/* HERO SECTION */}
        <ZoomInOut 
          images={width > 800 ? heroImages : heroImagesMobile} 
          text={quotes} 
          firma={firmaBiancaClaudio} 
          buttonClass='absolute left-[1.5rem] 2xl:left-[3rem] ' href='/libri'
        />

        {/* INTRO */}
        <MinimalSection
          orientation='left' 
          background='bg-slate-100' 
          title="Claudio Boso"
          titleClass='text-4xl text-black '
          description={description}
          descriptionClass={`2xl:py-6 text-black ${width < 1600 ? 'text-base' : 'text-xl' }`}
          href='/storia'
          imageUrl={ClaudioBoso2}
          imgOpacity="opacity-100"
          strength='0.14'
          lerp='0.30'
          buttonClass1='block mb-9'
          buttonClass2='hidden'
          darkButton={false}
          firstButtonText='Leggi di più'
        /> 
      </div>

      {/* FOOTER */}
      <FooterNew/>
    </>
  )
}

export default HomePage;
