import React, { useState } from 'react';

import infanzia from '../assets/polaroid/1.png';
import studi from '../assets/polaroid/2.png';
import mestre from '../assets/polaroid/3.png';
import bivio from '../assets/polaroid/4.png';
import careHolding from '../assets/polaroid/5.png';
import madre from '../assets/polaroid/6.png';
import oroPuroFisico from '../assets/polaroid/7.png';
import careIsGold from '../assets/polaroid/8.png';
import Navbar from './Navbar';
import FooterNew from './FooterNew';
import Loading3DText from './Loading3DText';

export default function StoriaMobile({startExperience}) {
const [isLoading, setIsLoading] = useState(true); // Manage the loading state
const [loading, setLoading] = useState(true);

  const images = [infanzia, studi, mestre, bivio, careHolding, madre, oroPuroFisico, careIsGold];
  const descriptions = [
    "Sono nato a Campitello di Fassa, un piccolo paesino di montagna in provincia di Trento, e sono figlio di una giovane ragazza madre che, lavorando come cameriera stagionale, si è sempre impegnata con sacrificio e determinazione per mantenermi. La preoccupazione per la mancanza di soldi era una costante, e questo mi ha insegnato fin da piccolo il valore delle cose semplici e mi ha fatto capire che - nella vita - niente arriva senza fatica. ",

    "Terminati gli studi, iniziai a lavorare presso uno studio di geometra, maturando le prime esperienze professionali. Dopo alcuni anni, aprii il mio studio: partii da solo, con determinazione e spirito imprenditoriale, fino a costruire una realtà che in breve tempo mi permise di assumere tre dipendenti. Fu proprio uno di loro ad avviarmi nel settore che avrebbe cambiato la mia vita: il mondo finanziario, in cui entrai con timidezza e riserbo. ",

    "Tempo dopo mi trasferii a Mestre, per cercare un mercato più vasto. Qui, iniziai un periodo di grandi sacrifici: ero solo, lavoravo 12 ore al giorno e spesso il frigo era vuoto... Un momento della mia vita che ricordo ancora e che mi ha sicuramente temprato.  Amavo ciò che facevo, sentivo che il percorso era quello giusto… anche se è stato tutto in salita, e le difficoltà non sono certo mancate. Con il tempo, ebbi la possibilità di imparare come creare, gestire e motivare una squadra; così, dopo anni di dura gavetta ma anche di grandi traguardi e importanti risultati, diventai amministratore della SpA italiana di un'importante azienda svizzera.",

    "Poi, nel 2008, mi trovai davanti ad un bivio: potevo scegliere di ritirarmi dal mercato, forte di una piena libertà economica, oppure investire la mia esperienza e le mie competenze in qualcosa di nuovo. Avevo già tutto ciò che desideravo dal punto di vista professionale e finanziario, eppure, sentivo che la mia missione non era compiuta. D’altra parte, la vita si articola in tre fasi: la prima in cui impari il “mestiere”, la seconda in cui metti pratica ciò che hai appreso, e l’ultima, in cui restituisci agli altri tutto quello che hai imparato, per dare loro la stessa possibilità che hai avuto tu. Per me, quel momento era arrivato.",

    "Così nel 2009, dopo oltre 20 anni di esperienza e un periodo di profonda riflessione, decisi di fondare Care Holding S.p.A.: qualcosa di completamente nuovo e innovativo, creato “dal nulla” grazie a tutte le competenze che avevo acquisito nel tempo… un sogno divenuto realtà. Ma c’è una cosa bella dei sogni: non hanno limiti, e il mio non era ancora finito.",

    "Così mi chiesi: come posso continuare a garantire alle persone sicurezza e stabilità, stando al passo con i tempi? La risposta arrivò in uno dei momenti più difficili della mia vita: la perdita di mia madre. Scoprii con grande sorpresa che nel corso degli anni aveva accantonato per me un piccolo tesoro in Oro Puro Fisico. ",

    "Così, un po’ per curiosità, un po’ perché lavorando in ambito finanziario ne capivo il linguaggio, cominciai ad informarmi su questo mondo. E ne rimasi letteralmente affascinato, colpito, conquistato. Attentissimo alla qualità dei prodotti, capii che l’Oro Puro Fisico è veramente il miglior investimento del mercato. Continuai la ricerca, studiai moltissimo, andai avanti per giorni, settimane, mesi; vissi un’evoluzione personale oltre che professionale. Ed è così, dalla volontà di offrire l’eccellenza del miglior investimento, che è nata Careisgold S.p.A., una realtà grazie alla quale investire nel bene rifugio per eccellenza non è più un lusso per pochi, ma un sogno realizzabile da chiunque. ",

    "Non dimenticherò mai le mie radici: le mie umili origini mi hanno insegnato il valore delle piccole cose e la forza di credere nei sogni, anche nei momenti più difficili. Oggi il mio scopo non è solo quello di costruire, ma di restituire: seguire dei valori autentici, mantenere le promesse e dare significato a ogni stretta di mano. Credo fermamente che la parola data faccia ancora la differenza e che il vero successo si costruisca sul benessere condiviso. Sono convinto che le grandi imprese abbiano bisogno di tempo e vadano realizzate insieme, con fiducia e collaborazione autentica. Solo così possiamo creare qualcosa di grande e che duri nel tempo. È questo il mio scopo, e farò di tutto per perseguirlo, con impegno e dedizione, giorno dopo giorno. …D’altra parte, questo è solo l’inizio! ",
  ];

  const handleEndLoading = () => {
    setIsLoading(false);
    setTimeout(() => {
      setLoading(false);
    },3000)
  }
  
  return (
    <>
    <div className='border-b-2'>
    <Navbar/>
    {loading &&
          <div
            className={`bg-black text-white fixed h-screen w-full z-40 ${isLoading ? '' : 'fade-out-experience'}`}
          >
            <div className="flex items-center justify-center h-screen w-full">
            <Loading3DText handleStartExperience={handleEndLoading} />
            </div>
          </div>
    }
      {images.map((image, index) => (
        <div className=' flex items-center justify-center flex-col' key={index}>
          {/* Image Section */}
          {/*  */}
          <div className='flex flex-col justify-center text-center items-center mt-20'>
            <img
              data-aos="zoom-in"
              data-aos-duration="1500"
              src={image}
              alt={`Slide ${index + 1}`}
              style={{ width: '80%', height: 'auto', objectFit: 'cover', borderRadius: '10px', }}
            />
          </div>
          {/* Description Section */}
          <div className='text-justify py-8 px-5'>
            <p data-aos-duration="3000" data-aos="fade-up" className='text-white text-xl'>{descriptions[index]}</p>
          </div>
        </div>
      ))}
      <div className="text-white text-left w-full flex justify-center my-20">
          <a href="https://careisgold.it" target="_blank" rel="noopener noreferrer" >
            <button className="progress-button-light border-slate-50 border text-slate-50 px-10 py-5">Visita careisgold.it</button>
          </a>
      </div>
    </div>
    <FooterNew/>
    </>
  );
}
